import icons from "../icons";
import logo from '../logo/logoDark.svg'

const instagram = "https://www.instagram.com/kvartel/"
const facebook = "https://www.facebook.com/kvartel/"
const appStore = 'https://apps.apple.com/ru/app/kvartel/id1502964430'
const playMarket = 'https://play.google.com/store/apps/details?id=com.kvartel'
const webKv = "https://kvartel.com"
const lkWeb = "http://lk.kvartel.com/"
const playMarketKb = "https://play.google.com/store/apps/details?id=ru.kvartel.business"
const appStoreKb = "https://apps.apple.com/ru/app/kvartel-business/id1583272233"
const webKB = 'https://kvartelbusiness.ru'



const commonData = {
    prodURL: webKB,
    logo:logo,
    commonTitle: 'Kvartel Business | ',
    commonTitleSm:'Kvartel Business',
    supportPhone: '+7(926) 019-78-25',
    supportPhoneHref: "tel:+79260197825",
    whatsapp: 'https://wa.me/79260197825',
    supportTelegram: 'https://t.me/kvartel',
    supportEmail: 'info@kvartel.com',
    supportEmailHref: "mailto:info@kvartel.com",
    address: '129164, Москва, ул. Маломосковская 18к1',
    zipcode:'129164',
    addressLocality:'Москва',
    streetAddress:'ул. Маломосковская 18к1',
    addressRegion:'Россия',
    description:'Kvartel Business - это компания, предоставляющая услуги по управлению недвижимостью в Москве. Мы помогаем собственникам сдавать недвижимость посуточно в аренду и управлять ею',
    playMarket: playMarket,
    appStore: appStore,
    instagram: instagram,
    facebook: facebook,
    mapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2241.881932212814!2d37.649890299999996!3d55.812650100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b535dce864be19%3A0x9167f8f000ede2da!2z0JzQsNC70L7QvNC-0YHQutC-0LLRgdC60LDRjyDRg9C7LiwgMTgsINCc0L7RgdC60LLQsCwg0KDQvtGB0YHQuNGPLCAxMjkxNjQ!5e0!3m2!1sru!2str!4v1675550839991!5m2!1sru!2str",
    footerAppDownloadBlock: {
        title: 'Kvartel Home Sharing',
        links: [
            {
                link: webKv,
                icon: icons.web,
                alt: "Kvartel home sharing"
            },
            {
                link: playMarket,
                icon: icons.googlePlay,
                alt: "Kvartel home sharing- Google Play",
            },
            {
                link: appStore,
                icon: icons.appStore,
                alt: "Kvartel home sharing - App Store",
            }
        ]
    },
    KBAppDownloadData: {
        title: 'Kvartel Business личный кабинет',
        links: [
            {
                link: lkWeb,
                icon: icons.web,
                alt: "Kvartel Business"
            },
            {
                link: playMarketKb,
                icon: icons.googlePlay,
                alt: "Kvartel Business - Google Play",
            },
            {
                link: appStoreKb,
                icon: icons.appStore,
                alt: "Kvartel Business - App Store",
            }
        ]
    },
    footerFollowUsBlock: {
        title: 'Следи за нами',
        links: [
            {
                link: instagram,
                icon: icons.instagram,
                alt: "Kvartel - Instagram",
            },
            {
                link: facebook,
                icon: icons.facebook,
                alt: "Kvartel - Facebook",
            }
        ]
    },
}

export default commonData;
