import MainCard from "../components/templates/homePage/mainCard";
import React, {Component} from "react";
import Advantages from "../components/templates/homePage/Advantages";
import CalcBlock from "../components/templates/homePage/CalcBlock";
import HowWeWork from "../components/templates/homePage/HowWeWork";
import WhiteBoxTextCenter from "../components/templates/WhiteBoxTextCenter";
import tariffData from "../assets/data/tariffData";
import ProductCardNI from "../components/templates/ProductCardNI";
import Divider from "../components/templates/Divider";
import WhiteBoxTextLeft from "../components/templates/WhiteBoxTextLeft";
import equipmentData from "../assets/data/equipmentData";
import ProductBox from "../components/card/productBox";
import Reviews from "../components/templates/Reviews";
import typography from "../css/typography";
import styleSchema from "../css/styleSchema";
import HomeHelmet from "../components/helmet/HomeHelmet";

export default class Home extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHelmet/>
                <MainCard/>
                <HowWeWork/>
                <Advantages/>
                <WhiteBoxTextCenter
                    title={<h2
                        style={typography({color: styleSchema.greenColorDark.color}).h2}>Наши тарифы</h2>}
                    description={'Выгодные тарифы на управление апартаментами от компании Kvartel, удобные всем нашим клиентам. Наша комиссия фиксированная и самая низкая на рынке! Найдите нужную опцию управления апартаментами от компании Kvartel.'}
                    link={'/tariff'}
                    linkName={'Сравнить тарифы'}
                    content={<ProductCardNI tariff={tariffData}/>}
                />
                <div className={`container`}>
                    <div className={`mb-5`}>
                        <Divider theme={'dark'}/>
                    </div>
                </div>
                <WhiteBoxTextLeft
                    title={<h2
                        style={typography({color: styleSchema.greenColorDark.color}).titleLarge}>Наше оборудование</h2>}
                description={'Мобильное приложение Kvartel Business позволит вам отследить статус замка, а также вы сможете увидеть время, в которое он был закрыт/открыт.'}
                    contactText={'Хотите приобрести оборудование?'}
                    content={
                        equipmentData.map((item, index) => {
                            let r = (Math.random() + 1).toString(36).substring(7);

                            return (
                                <ProductBox data={item} index={r + index} key={r + index}/>
                            )
                        })}
                />
                <Reviews/>
                <CalcBlock/>
            </React.Fragment>
        )
    }
}

