import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";
import LinkButton from "./linkButton";
import typography from "../../css/typography";

const WhiteBoxTextCenter = (props) => {
    const classes = Style();

    return (
        <div className={`row m-0 p-0 justify-content-center`}>
            <div className={`col-12 ${classes.conditionsBlock}`}>
                <div className={`${classes.textBox}`}>
                    <div className={`row justify-content-center`}>
                        {props.title}
                    </div>
                    <div className={`row justify-content-center ${classes.description} col-lg-5`}>
                        {props.description}
                    </div>
                    <div className={`${classes.textBoxButton}`}>
                        {props.link !== '' ?
                            <LinkButton link={props.link} linkName={props.linkName}/> : null}
                    </div>
                </div>
                <div className={`${classes.contentBlock}`}>
                    {props.content}
                </div>
            </div>
        </div>
    )
}

const Style = () => {
    const useStyles = createUseStyles({
        conditionsBlock: {
            position: 'relative',
            backgroundColor: styleSchema.white.color,
            paddingTop: '3rem',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                paddingTop: '1rem',
            },
        },
        textBox: {
            textAlign: "center",
            padding: [0, 20, 0, 20]
        },
        title: typography({color: styleSchema.greenColorDark.color}).h2,
        description: {
            fontFamily: styleSchema.fontLight,
            fontSize: 18,
            color: styleSchema.greenColorDark.color,
            margin: "auto",
        },
        textBoxButton: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
        contentBlock: {
            margin: [50, 0, 10, 0],
            display: 'flex',
            justifyContent: 'center',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                margin: [40, 0, 10, 0],
                display: 'flow',
            },
        },
    })
    return useStyles()
}

export default WhiteBoxTextCenter

