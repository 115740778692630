import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";
import icons from "../../assets/icons";


import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import Collapsible from 'react-collapsible';

const ProductCard = (products) => {
    const classes = useStyles();

    return (
        <div className="row justify-content-center m-0">
        <div className={`col-lg-11`}>
        <div className="row justify-content-center mb-5 pb-5">

            {products.map((product, index) => {
                return (
                    <div className="col-lg-6 col-md-12 col-sm-12  mt-5 " key={index}>
                        <div className={`row m-0 ${classes.product}  ${styleSchema.aos}`} data-aos={styleSchema.data_aos}
                             data-aos-delay="300">
                            <div className={`row ${classes.title} m-0`}>
                                <h2 className={`${styleSchema.h2}`}>{product.title}</h2>
                            </div>
                            <div className="row m-0">
                                <div className="col-lg-6 col-md-6 col-sm-12 ">
                                    <div className={classes.productPhoto}>
                                        <img className={classes.img} src={product.img} alt={product.imgAlt}/>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className={classes.productInfo}>
                                        <div className={classes.price}>
                                            <i>{icons.ruble}</i>{product.price}
                                        </div>
                                        {Variant(classes, product.colors)}
                                        <div className={classes.description}>
                                            <div>{product.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {Details(classes, product.details)}
                        </div>
                    </div>
                )
            })}
        </div></div></div>
    )
}


const useStyles = createUseStyles({

    product: {
        borderRadius: 40,
        boxShadow: styleSchema.olive.boxShadow,
        margin: 0,
        height: '100%',
        alignContent: 'baseline',
        background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.35) 21.75%, rgba(255, 255, 255, 0) 100%)',
       // maxWidth:600,
    },
    title: {
        textAlign: 'center',
        margin: [20, 0],
        padding: 30,
        background: styleSchema.olive.color,
        color: styleSchema.white.color,
        borderRadius: [40, 40, 0, 0],
        fontFamily: styleSchema.fontBold
    },
    subTitle: {
        color: styleSchema.greenColorDark.color,
       // textTransform: 'uppercase',
        fontFamily: styleSchema.fontMedium,
        fontSize: 16,
        marginBottom: 10,
    },

    //Photo block
    productPhoto: {
        position: 'relative',
    },
    img: {
        filter: 'saturate(150%) contrast(120%) hue-rotate(10deg) drop-shadow(1px 20px 10px rgba(0, 0, 0, 0.3))',
        maxWidth: '95%',
        padding:[20,0],
        [`@media screen and ${styleSchema.mobScreen}`]: {
            height: 200,
            display: 'flex',
            margin: 'auto',
            padding: [20,0]
        },
    },

    //Product info
    productInfo: {
        padding: [10, 0, 20, 15],
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: [20,0],
        }
    },
    price: {
        fontFamily: styleSchema.fontBold,
        color: styleSchema.greenColorDark.color,
        fontSize: 50,
        "& i": {
            paddingRight: 25,
            "& svg": {
                height: 25
            }
        },
        [`@media screen and ${styleSchema.mobScreen}`]: {
            fontSize: 30,
        },
    },

    //variant
    variant: {
        margin: [10, 0],
    },
    productColorList: {
        listStyleType: 'none',
        padding: 0,
        width: 'fit-content',
        "& li": {
            padding: 0,
        },
    },
    productColor: {
        width: 30,
        height: 30,
        borderRadius: 50,
        padding: 0,
        cursor: 'pointer',
        margin: 5,
    },

    //description
    description: {
        color: styleSchema.greenColorDark.color,
        fontFamily: styleSchema.fontLight,
    },
    details: {
        textAlign: 'end',
        padding: 20,
        '& span': {

            fontFamily: styleSchema.fontMedium,
            color: styleSchema.greenColorDark.color,

        },
        '& span:hover, :active': {
            color: styleSchema.greenColorDark.color,
        },
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: [0,20,20,20],
        },
    },
    detailsOpen: {
        textAlign: 'end',
        padding: 20,
        '& span': {
            color: styleSchema.greenColorDark.color,
            fontFamily: styleSchema.fontMedium,
        },
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: [0,20],
        },
    },
    detailsList: {
        listStyleType: 'none',
        textAlign: 'start',
        color: styleSchema.greenColorDark.color,
        fontFamily: styleSchema.fontLight,
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: 0,
            paddingLeft: 5,
        },
    },
    detailItem: {
        color: styleSchema.greenColorDark.color,
        fontFamily: styleSchema.fontLight,

    },
})

const Variant = (classes, colors) => {
    if (colors.length !== 0) {
        return (
            <div className={classes.variant}>
                <div className={classes.subTitle}>Доступные цвета</div>
                <ul className={`ul-column ${classes.productColorList}`}>
                    {colors.map((color, index) => {
                        let colorId = `colorId_${index}`;
                        return (
                            <React.Fragment key={colorId}>
                                <li
                                    id={colorId} key={colorId}
                                    className={classes.productColor}
                                    style={{
                                        backgroundColor: color.color
                                    }}></li>
                                <Tooltip
                                    anchorSelect={`#${colorId}`}
                                    content={color.colortitle
                                    }
                                />
                            </React.Fragment>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

const Details = (classes, details) => {
    if (details.length !== 0) {
        return (

            <div className="row">
                <Collapsible trigger={'Детальнее'} className={classes.details} openedClassName={classes.detailsOpen}>
                    <div className="row mt-3">

                        {details.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div>
                                        <div className={`text-center ${classes.subTitle}`}>{item.title}</div>
                                        <ul className={classes.detailsList}>
                                            {item.details.map((item, key) => {
                                                return (
                                                    <React.Fragment key={`${index}_${key}_detail`}>
                                                        <li>{item}</li>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </Collapsible>
            </div>

        )
    }
}

export default ProductCard;
