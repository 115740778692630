import React, {Component} from "react";
import ProductCardNI from "../components/templates/ProductCardNI";
import DataTable from "../components/templates/DataTable";
import styleSchema from "../css/styleSchema";
import {createUseStyles} from "react-jss";
import tariffData from "../assets/data/tariffData";
import WhiteBoxTextLeft from "../components/templates/WhiteBoxTextLeft";
import tariffCompareData from "../assets/data/TariffCompareData";
import WhiteBoxTextCenter from "../components/templates/WhiteBoxTextCenter";
import commonData from "../assets/data/commonData";
import typography from "../css/typography";
import TariffHelmet from "../components/helmet/TariffHelmet";

const title = `${commonData.commonTitle}Тарифы`
const contactText = 'Появились вопросы?';
export default class Tariff extends Component {
    static displayName = Tariff.name;

    componentDidMount() {
        document.title = title
    }

    render() {
        return (
            <div className="container">
                <TariffHelmet/>
                <div className="mt-5">
                    <WhiteBoxTextLeft
                        title={<h2
                            style={typography({color: styleSchema.greenColorDark.color}).titleLarge}>Наше вознаграждение зависит от ваших доходов</h2>}
                        contactText={contactText}
                        bg={false}
                        content={<Description/>}
                    />
                    <div className={`mt-5`}>
                        <ProductCardNI tariff={tariffData}/>
                    </div>
                        <WhiteBoxTextCenter
                            id='compare'
                            title={<h2
                                style={typography({color: styleSchema.greenColorDark.color}).h2}>Сравнение тарифов</h2>}
                            link={''}
                            content={
                                <div className="container mb-5">
                                    <div className={`row`}>
                                        <div className={`col`}>
                                            <DataTable data={tariffCompareData}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                </div>
            </div>
        )
    }
}

const Description = () => {
    const Style = () => {
        const useStyles = createUseStyles({
            descriptionBlock: {
                fontFamily: styleSchema.fontMedium,
                fontSize: 16,
                color: styleSchema.greenColorDark.color,
                marginLeft: 40,
                width: 'fit-content',
                [`@media screen and ${styleSchema.screenMax900}`]: {
                    margin: [0, 40],
                },
                [`@media screen and ${styleSchema.mobScreen}`]: {
                    margin: [0, 25],
                    fontFamily: styleSchema.fontLight,
                },
            },
        })
        return useStyles()
    }

    const classes = Style();

    return (
        <div className={`${classes.descriptionBlock}`}>
            <div className={`mb-3`}>
                Сдавай свою недвижимость в Москве вместе с управляющей компанией
                Kvartel Business, специализирующейся на доверительном управлении
                квартирами. Мы увеличим доходность от сдачи жилья в два раз за счет
                полной автоматизации процесса. Выберите подходящий Вам вариант
                сотрудничества!
            </div>
            <div className={`mb-3`}>
                Выгодные тарифы на управление апартаментами, удобные всем нашим
                клиентам. Наша комиссия фиксированная и самая низкая на рынке! Найдите
                нужную опцию управления апартаментами от компании Kvartel и убедитесь в
                преимуществах нашего сервиса.
            </div>
        </div>
    )
}
