import commonData from "../commonData";

/*const f = {
    "@context": "https://schema.org/",
    "@type": "Offer",
    "name": "Тарифы",
    "description": `Тарифы на услуги ${commonData.commonTitleSm}.`,
    "url": "/tariff",


    "priceCurrency": "rub",
    "availability": "https://schema.org/InStock",
    "seller": {
        "@type": "Organization",
        "name": commonData.commonTitleSm,
        "telephone": commonData.supportPhone,
        "priceRange": "комиссия от 10% до 20% ",

    }
}*/
export const tariff = {
    "@context": "https://schema.org/",
    "@type": "Service",
    "serviceType": "Управление апартаментами в Москве для сдачи в посуточной аренде",
    "provider": {
        "@type": "LocalBusiness",
        "name": commonData.commonTitleSm,
        "telephone": commonData.supportPhone,
        "priceRange": "комиссия от 10% до 20% ",
        'image': commonData.logo,
        'address':commonData.addressLocality
    },
    "areaServed": {
        "@type": "State",
        "name": 'Москва'
    },
    "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Тарифы на управление апартаментами",
        "itemListElement": [
            {
                "@type": "OfferCatalog",
                "name": "СТАНДАРТ 10%",

               /* "itemListElement": [
                    {
                        "@type": "Offer",
                        "itemOffered": {
                            "@type": "Service",
                            "name": "{{CHILD SERVICE #1}}"
                        }
                    },
                    {
                        "@type": "Offer",
                        "itemOffered": {
                            "@type": "Service",
                            "name": "{{CHILD SERVICE #2}}"
                        }
                    },
                    {
                        "@type": "Offer",
                        "itemOffered": {
                            "@type": "Service",
                            "name": "{{CHILD SERVICE #3}}"
                        }
                    }
                ]*/
            },
            {
                "@type": "OfferCatalog",
                "name": "СТАНДАРТ PLUS 15%",
            },
            {
                "@type": "OfferCatalog",
                "name": "ПРЕМИУМ 20%",
            }
        ]
    }
}
