import Contacts from "../pages/Contacts";
import Locks from "../pages/Locks";
import Tariff from "../pages/Tariff";
import {Faq} from "../pages/Faq";

const NavMenuRoutes = [
   {
        path: '/tariff',
        element: <Tariff />,
        title: 'тарифы'
    },
    {
        path: '/equipment',
        element: <Locks />,
        title: 'оборудование'
    },
    {
        path: '/contacts',
        element: <Contacts/>,
        title: 'Контакты'
    },
    {
        path: '/faq',
        element: <Faq/>,
        title: 'Частые вопросы'
    }
]

export default NavMenuRoutes;
