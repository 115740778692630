import styleSchema from "../../css/styleSchema";
import {createUseStyles} from "react-jss";
import React from "react";
import CardBox from "./cardBox";
import LinkButton from "../templates/linkButton";


const ProductBox = (props) => {
    const classes = Style();
    return (
        <div className={`col-lg col-md-12 col-sm-12 mb-5`}>
            <CardBox
                key={props.index}
                content={
                    <div className={classes.box}>
                        <div className={`row mt-4 justify-content-center`}>
                            <img className={`${classes.img}`} src={props.data.img} alt={props.data.imgAlt}/>
                        </div>
                        <div className={`row mt-4 justify-content-center ${classes.title}`}>{props.data.title}</div>
                        <div className={`row mt-4 ${classes.description}`}>{props.data.description}</div>

                        <div className={`row mt-4`}>
                            {props.data.link !== '' ?
                                <LinkButton link={props.data.link} linkName={props.data.linkName}/> : null}
                        </div>
                    </div>
                }
            />
        </div>
    )
}

const Style = () => {
    const useStyles = createUseStyles({
        box: {
            padding: [0, 15],
            height: '100%',
        },
        img: {
            height: 200,
            width: 200,
            objectFit: 'cover',
            filter: 'saturate(150%) contrast(120%) hue-rotate(10deg) drop-shadow(1px 20px 10px rgba(0, 0, 0, 0.3))',
            position: 'relative',
        },
        title: {
            fontSize: 16,
            fontFamily: styleSchema.fontMedium,
            color: styleSchema.greenColorDark.color,
            textTransform: "uppercase",
            textAlign: "center",
        },
        description: {
            fontSize: 16,
            fontFamily: styleSchema.fontLight,
            color: styleSchema.greenColorDark.color,
        },
    })
    return useStyles()
}
export default ProductBox
