import {createUseStyles} from "react-jss";
import React from "react";
import styleSchema from "../../../css/styleSchema";
import {Radio} from "../Radio";
import ContactUsForm from "../ContactUsForm";
import {Popup} from "../Popup";
import offer from "../../../assets/dogovor.pdf";

const CalcBlock = () => {
    const classes = Style();

    return (
        <section className={` ${classes.bgGreen} `}>
            <div className={`col-12`}>
                <div className={`row m-0`}>
                    <div className={`col-lg-7 col-md-12 col-sm-12 backgroundImg ${classes.backgroundImg} ${styleSchema.aos}`}
                         data-aos={styleSchema.data_aos}
                         data-aos-delay="200">

                        <div className={`${classes.calcBlock} container`}>
                            <div className={classes.title}>Сколько можно заработать, сдавая свою квартиру через Kvartel
                                Business в Москве?
                            </div>
                            <Radio/>
                            <div className={classes.notice}>
                                *Расчёт является приблизительным и не является публичной офертой.
                            </div>
                            <div className={classes.publickOff}>
                               {/* <Popup
                                    popupButton={'Публичная оферта'}
                                    popupTitle={'Публичная оферта'}
                                    content={ <iframe src={offer} className={'offerFrame'} title={'offer'}></iframe>}
                                />*/}
                            </div>
                        </div>
                    </div>
                    <div className={`col-lg-5 col-md-12 col-sm-12 ${styleSchema.aos}`}
                         data-aos={`zoom-in`}
                         data-aos-delay="200"
                    >
                        <div className={`container mt-5`}>
                            <div className={`${classes.title} `}>Оставить заявку</div>
                            <div className={`${classes.calcBlock} ${classes.width500} container`}>
                                <ContactUsForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CalcBlock

const Style = () => {
    const useStyles = createUseStyles({
        offer:{
            width:600,
            height:500,
        },
        bgGreen: {
            backgroundColor: styleSchema.greenColorDark.color
        },
        backgroundImg: {


        },
        calcBlock: {
            padding: ['5%', '10%'],
            position: 'relative',
        },
        title: {
            textAlign: 'center',
            fontSize: 30,
            color: styleSchema.white.color,
            textTransform: 'uppercase',
            fontFamily: styleSchema.fontMedium,
            verticalAlign: 'middle',
        },
        notice: {
            color: styleSchema.lightGrey,
            marginTop: '5em',
            fontFamily: styleSchema.fontLight,
        },
        publickOff: {
            color: styleSchema.lightGrey,
            marginTop: '1em',
            fontFamily: styleSchema.fontLight,
        },
        width500: {
            maxWidth: 500,
        },
        [`@media screen and ${styleSchema.mobScreen}`]: {
            calcBlock: {
                padding: '1em',
            },
            title: {
                fontSize: '19px',
                textTransform: ''
            },
            notice: {
                marginTop: 15
            },
            maxWidth: {},

        },
        [`@media screen and ${styleSchema.tabScreen}`]: {
            calcBlock: {},
            width500: {
                maxWidth: '100%'
            },
        }

    })
    return useStyles()
}
