import commonData from "../commonData";

export const home={
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": `Управляющая компания ${commonData.commonTitleSm}`,
    "url": commonData.prodURL,
    "description": "Сдать квартиру в управление для посуточной аренды в Москве и МО",
    "image": commonData.logo
}
