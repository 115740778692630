import Home from '../pages/Home';
import Contacts from "../pages/Contacts";
import Locks from "../pages/Locks";
import Tariff from "../pages/Tariff";
import React from "react";
import NotFoundPage from "../pages/NotFoundPage";
import {Faq} from "../pages/Faq";


const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/equipment',
        element: <Locks/>,
    },
    {
        path: '/contacts',
        element: <Contacts/>,
    },
    {
        path: '/tariff',
        element: <Tariff/>,
    },
    {
        path: '/faq',
        element: <Faq/>,
    },
    {
        path: '/page-not-found',
        element: <NotFoundPage/>,
    }
]

export default AppRoutes;
