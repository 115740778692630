import cylinder from "../lock/CYLINDER2.webp";
import lockKvartel from "../lock/lockKvartel.webp";
import miniceif from "../lock/miniceif.webp";
import BluetoothGateway from "../lock/wifi.webp";

const equipment = [
    {
        title: 'Замок Kvartel',
        price: '11 500',
        img: lockKvartel,
        imgAlt: 'Замок Kvartel',
        description: `Замок KVARTEL — идеален для мини-отелей, апартаментов, офисов как электронный замок с дистанционным управлением и открытием.`,
        colors: [
            {
                colortitle: 'Серебрянный',
                color: '#C0C0C0'
            },
            {
                colortitle: 'Черный',
                color: '#000'
            },
        ],
        details: [
            {
                title: "Комплектация базовая",
                details: [
                    'Электронный замок - 1шт',
                    'Комплект установочный - 1шт',
                    'Уникальный магнитный ключ - 2шт',
                    'RFID-метка MIFARE - 2шт',
                    'Инструкция по установке и программированию - 1шт',
                ],
            },
            {
                title: 'Дополнительная комплектация',
                details: [
                    'WiFi- шлюз',
                    'Элементы питания AAA - 4шт',
                    'IC-карта в любом количестве',
                ],
            },
            {
                title: 'Управление замком',
                details: [
                    'Пароль на сенсорном дисплее',
                    'IC-карта',
                    'Механический ключ',
                    'С помощью телефона/планшета через интернет',
                ],
            },
        ],
        link: '',
        linkName: 'Заказать сейчас',
    },
    {
        title: "SMART-CYLINDER",
        price: '9 500',
        img: cylinder,
        imgAlt: 'Цилиндрический замок',
        link: '',
        linkName: '',
        description: 'Электронный цилиндр управляется RFID, кодом, смартфоном. Устанавливается в любой замок за 5 минут одной отверткой.',
        details: [
            {
                title: "Комплектация базовая",
                details: [
                    'Электронный цилиндр - 1шт',
                    'Комплект установочный - 1шт',
                    'Уникальный магнитный ключ - 2шт',
                    'RFID-метка MIFARE - 2шт',
                    'Инструкция по установке и программированию - 1шт',
                ],
            },
            {
                title: 'Дополнительная комплектация',
                details: [
                    'WiFi- шлюз',
                    'Элементы питания AAA - 3шт',
                    'IC-карта в любом количестве',
                ],
            },
            {
                title: 'Управление замком',
                details: [
                    'Пароль на сенсорном дисплее',
                    'IC-карта',
                    'Механический ключ',
                    'С помощью телефона/планшета через интернет',
                ],
            }
        ],
        colors: [
            {
                colortitle: 'Серебрянный',
                color: '#C0C0C0'
            },
            {
                colortitle: 'Черный',
                color: '#000'
            },
        ],
    },
    {
        title: "kvartel MINI KEY SAFE",
        price: '2 590',
        img: miniceif,
        description: 'Экономное решение для бесконтактной передачи ключей. 4-значный пароль, надёжное крепление и запор — гарантия сохранности ваших ключей.',
        colors: [],
        details: [],
        link: '',
        linkName: '',
    },
    {
        title: "WiFi GATE G2",
        price: '3 800',
        img: BluetoothGateway,
        description: 'WIFI-шлюз от Kvartel для дистанционного управления замками. Управляйте и отслеживайте события замка онлайн откуда угодно.',
        colors: [],
        details: [],
        link: '',
        linkName: '',
    }
]

export default equipment
