import React from "react";
import NavMenuRoutes from "../../../routes/NavMenuRoutes";
import {createUseStyles} from "react-jss";
import styleSchema from "../../../css/styleSchema";

const sideMenu = props => {
    let classes = Style();
    let sideMenuClasses = [classes.sideMenu];

    if (props.show) {
        sideMenuClasses = [classes.sideMenu, classes.open];
    }

    return (
        <nav className={sideMenuClasses.join(" ")}>
            <ul className={classes.sideMenuList}>
                {
                    NavMenuRoutes.map((navItem, index) => {
                        return (
                            <li key={index} className={classes.sideMenuItem}>
                                <a className={classes.sideMenuNavLink} href={navItem.path}>
                                    {navItem.title}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    );
};

const Style = () => {
    const useStyles = createUseStyles({
        sideMenu: {
            height: '100%',
            background: 'white',
            boxShadow: styleSchema.boxShadow,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '70%',
            maxWidth: 400,
            transform: 'translateX(-100%)',
            transition: 'transform 0.3s ease-out',
            zIndex: 200,
            ['@media screen and (min-width: 759px)']: {
                    display: 'none',
            },
        },
        open: {
            transform: 'translateX(0)',
        },
        sideMenuList: {
            height: '100%',
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        sideMenuItem: {
            margin: '0.5rem 0',
        },
        sideMenuNavLink: {
            textDecoration: 'none',
            color: styleSchema.greenColorDark.color,
            textTransform:'capitalize',
            fontSize: '1.2rem',
            fontFamily: styleSchema.fontMedium,
            '& :hover': {
                color: styleSchema.greenColorDark
            },
            '& :active': {
                color: styleSchema.greenColorDark
            },
        },
    });
    return useStyles()
}

export default sideMenu;
