const TariffCompareData = [
    {
        thService: 'Услуги',
        thPlan: ['Стандарт', 'Стандарт Plus', 'Премиум'],
        tdData: [
            {
                service: 'Персональный менеджер',
                data: [true, true, true],
            },
            {
                service: 'Личный кабинет',
                data: [true, true, true],
            },
            {
                service: 'Размещение объявлений',
                data: ['На 3-х каналах', 'На Всех каналах продаж', 'На Всех каналах продаж'],
            },
            {
                service: 'Управление ценообразованием',
                data: [true, true, true],
            },
            {
                service: 'Аналитика',
                data: [true, true, true],
            },
            {
                service: 'Онлайн платежи',
                data: [true, true, true],
            },
            {
                service: 'Техническая поддержка 24/7',
                data: [false, false, true],
            },
        ]
    },
    {
        thService: 'Подготовка объекта',
        thPlan: ['Стандарт', 'Стандарт Plus', 'Премиум'],
        tdData: [
            {
                service: 'Профессиональная фотосъемка',
                data: ['5 000 ₽', '3 500 ₽', 'Бесплатно'],
            },
            {
                service: 'Установка умного замка',
                data: ['3 000 ₽', '1 500 ₽', 'Бесплатно'],
            },
            {
                service: 'Аренда умного замка',
                data: ['1 200 ₽ в месяц', '600 ₽ в месяц', 'Бесплатно'],
            },
        ]
    },
    {
        thService: 'Уборка и поддержка',
        thPlan: ['Стандарт', 'Стандарт Plus', 'Премиум'],
        tdData: [
            {
                service: 'Управление обслуживающим персоналом',
                data: [true, true, true],
            },
            {
                service: 'Управление горничными',
                data: [true, true, true],
            },
            {
                service: 'Организация починки бытовой техники',
                data: [false, true, true],
            },
            {
                service: 'Проведение мелкого ремонта',
                data: [false, true, true],
            },
            {
                service: 'Закупки хоз. принадлежностей',
                data: [false, true, true],
            },
        ]
    },
    {
        thService: 'Консьерж сервис',
        thPlan: ['Стандарт', 'Стандарт Plus', 'Премиум'],
        tdData: [
            {
                service: 'Верификация гостей',
                data: [true, true, true],
            },
            {
                service: 'Коммуникация с Гостями до заезда',
                data: [true, true, true],
            },
            {
                service: 'Организация удаленного заезда',
                data: [true, true, true],
            },
            {
                service: 'Консьерж сервис 24/7 ',
                data: [false, true, true],
            },

        ]
    },
]

export default TariffCompareData
