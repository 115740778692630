import React, {Component} from 'react';

export class Container extends Component {
    render() {
        return (
            <div className={`row justify-content-center m-0 p-0`}>
                <div className={`col-12`}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
