import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";

const Button = (props) => {
    const classes = Style(props.theme);
    return (
        <button className={`${classes.btn} ${classes.btnColors}`}
                id={props.id}
                type={props.type}
                onClick={props.onClick}>
            {props.btnText}
        </button>
    )
}

const Style = (theme) => {
    let color, lgt = ''
    if (theme === 'light') {
        color = styleSchema.white.color
        lgt = styleSchema.greenColorDark.color
    } else {
        color = styleSchema.greenColorDark.color
        lgt = styleSchema.white.color
    }
    const useStyles =
        createUseStyles({
            btn: {
                width: 'inherit',
                padding:[5,10],
            },
            btnColors: {
                color: lgt,
                borderRadius: 5,
                fontFamily: styleSchema.fontMedium,
                //box-shadow: 0px 2px 6px rgb(225, 225, 255, 1);
/*                '--bs-btn-bg': color,
                '--bs-btn-border-color': lgt,
                '--bs-btn-hover-bg': lgt,
                ' --bs-btn-hover-color': color,
                '--bs-btn-hover-border-color': color,
                '--bs-btn-active-bg': lgt,
                '--bs-btn-active-border-color': color,
                ' --bs-btn-disabled-bg': color,
                '--bs-btn-disabled-border-color': lgt,*/
                backgroundColor: "transparent",
                borderColor: lgt,
            }
        })
    return useStyles()
}
export default Button
