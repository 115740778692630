import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {createUseStyles} from "react-jss";
import reviews from "../../assets/data/reviews";
import styleSchema from "../../css/styleSchema";
import {Fragment} from "react";
import UAParser from "ua-parser-js";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    }
};

const Reviews = ({req}) => {
    let userAgent;
    if (req) {
        userAgent = req.headers["user-agent"];
    } else {
        userAgent = navigator.userAgent;
    }

    const parser = new UAParser();
    parser.setUA(userAgent);

    console.log(parser.setUA(userAgent))

    const result = parser.getResult();
    const deviceType = (result.device && result.device.type) || "desktop";
    console.log(deviceType)
    return (
        <Fragment>
            <CarouselComponent deviceType={deviceType}/>
        </Fragment>
    );
};

const CarouselComponent = ({deviceType}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.reviewsBox}`}>
            <div className={`container `}>
                <div className={`row m-0 justify-content-center`}>
                    <div className={`col-11 ${classes.contentBox} `}>
                        <div className={`col-lg-3 col-md-12 col-sm-12 ${classes.title}`}>
                            Наши отзывы
                        </div>
                        <div className={`col-lg-9 col-md-12 col-sm-12 ${classes.carouselContainer}`}>
                            <Carousel
                                ssr
                                infinite={true}
                                deviceType={deviceType}
                                responsive={responsive}
                            >
                                {reviews.map((data, index) => {
                                    return (
                                        <div className={`${classes.box}`} key={index}>
                                            <div className={`row m-0 ${classes.reviewTextBox}`}>
                                                {data.text}
                                            </div>
                                            <div className={`row m-0 ${classes.clientBox}`}>
                                                <div className={`${classes.client}`}>
                                                    {data.clientName}
                                                </div>
                                                <div className={`${classes.period}`}>
                                                    {data.period}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reviews

const useStyles = createUseStyles({
    contentBox: {
        display: 'flex',
        flexDirection: 'row',
        padding: [40, 50],
        [`@media screen and ${styleSchema.screenMax900}`]: {
            padding: [20, 0],
            flexDirection: 'column',
        }
    },
    reviewsBox: {
        backgroundColor: styleSchema.olive.color
    },
    title: {
        fontSize: 24,
        fontFamily: styleSchema.fontBold,
        color: styleSchema.white.color,
        margin: "auto",
        [`@media screen and ${styleSchema.screenMax900}`]: {
            justifyContent: 'center',
            marginBottom: 15,
        },
    },
    carouselContainer: {
        justifyContent: "center",
        display: "flex",
    },
    box: {
        height: '100%',
        display: "flex",
        flexDirection: "column",
        backgroundColor: styleSchema.white.color,
        boxShadow: styleSchema.olive.boxShadow,
        borderRadius: 40,
        [`@media screen and ${styleSchema.mobScreen}`]: {
            // maxWidth: 300
        }
    },
    reviewTextBox: {
        fontSize: 16,
        fontFamily: styleSchema.fontLight,
        color: styleSchema.greenColorDark.color,
        background: styleSchema.white.color,
        padding: 20,
        flexGrow: 1,
        borderRadius: 40
    },
    clientBox: {
        backgroundColor: styleSchema.olive.darkOlive,
        color: styleSchema.white.color,
        padding: 20,
        display: 'flex',
        gap: 10,
        borderRadius: [0, 0, 40, 40]
    },
    client: {},
    period: {}
})
