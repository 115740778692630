import {Helmet} from "react-helmet";
import commonData from "../../assets/data/commonData";
import img from "../../assets/slide4.jpg";
import {faq} from "../../assets/data/faq";
import React from "react";

export default function FaqHelmet(){
    const title = `${commonData.commonTitle}Часто задаваемые вопросы`
    return(
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title}/>
            <meta name="description"
                  content={`Ответы на часто задаваемые вопросы по работе с ${commonData.commonTitleSm}.`}/>
            <meta name="keywords"
                  content="Kvartel, Kvartel Business, FAQ Kvartel, вопросы об сдаче квартиры посуточно, управление недвижимостью, сдать жилье посуточно в Москве и МО, платформа для аренды, сдача в аренду, бесконтактная аренда, посуточная аренда, аренда квартир, Kvartel приложение, бесконтактная сдача жилья, как оптимозировать сдачу квартиры, эффективность аренды, недвижимость FAQ, Kvartel вопрос-ответ, как увеличить доход от сдачи квартиры"/>
            <meta property="og:title" content={`Часто задаваемые вопросы | ${commonData.commonTitleSm}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`${commonData.prodURL}/faq`}/>
            <meta property="og:image" content={img}/>
            <meta property="og:description"
                  content={`Ответы на часто задаваемые вопросы по работе с ${commonData.commonTitleSm}.`}/>
            <script type={`application/ld+json`}>
                {JSON.stringify(faq)}
            </script>
        </Helmet>
    )
}
