import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";
import icons from "../../assets/icons";
import 'react-tooltip/dist/react-tooltip.css'

const ProductCard = (props) => {
    const classes = useStyles();

    return (
        <div className="row m-0 justify-content-center">
            {props.tariff.map((product, index) => {
                let r = (Math.random() + 1).toString(36).substring(7);
                let id=index+r
                return (
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-5 d-flex justify-content-center fit" key={id}>
                        <div className={`row ${classes.product}  ${styleSchema.aos}`} data-aos={styleSchema.data_aos}
                             data-aos-delay="300">
                            <div className={`${classes.title} m-0`}>
                                <h2 className={`${styleSchema.h2}`}>{product.title}</h2>
                                <h2 className={`${styleSchema.h2}`}>{product.price}</h2>
                            </div>

                            <div className={classes.description}>
                                <div>{product.description}</div>
                            </div>

                            <div className={classes.list}>
                                {product.details.map((item, index) => {
                                    let r = (Math.random() + 1).toString(36).substring(7);
                                    let id=index+r
                                    return (
                                        <React.Fragment key={`${id}_detail`}>
                                            <div className={classes.listItem}>
                                                <li className={classes.detailsList}>
                                                    <i className={classes.listIcon}>{icons.check}</i>
                                                    {item}
                                                </li>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


const useStyles = createUseStyles({

    product: {
        borderRadius: 40,
        boxShadow: styleSchema.olive.boxShadow,
        margin: 0,
        height: '100%',
        alignContent: 'baseline',
        backgroundColor: styleSchema.white.color,
        maxWidth: 450,

    },
    title: {
        textAlign: 'center',
        margin: [20, 0],
        padding: 15,
        background: styleSchema.olive.color,
        color: '#FFF',
        borderRadius: [40, 40, 0, 0],
        fontFamily: styleSchema.fontBold
    },
    subTitle: {
        color: styleSchema.greenColorDark.color,
        textTransform: 'uppercase',
        fontFamily: styleSchema.fontBold,
        fontSize: 16,
        marginBottom: 10,
    },

    // product list
    list: {
        margin: [20],
        width: "fit-content",
    },

    listIcon: {
        color: styleSchema.olive.color,
        marginRight: 20,
    },

    detailsList: {
        listStyleType: 'none',
        textAlign: 'start',
        color: styleSchema.greenColorDark.color,
        fontFamily: styleSchema.fontLight,

    },
    listItem: {
        marginBottom: 15,
    },

    //description
    description: {
        color: styleSchema.greenColorDark.color,
        fontFamily: styleSchema.fontMedium,
        margin: [15, 40, 0, 40],
        textAlign: 'center',
        width: "fit-content",
    },

    [`@media screen and ${styleSchema.mobScreen}`]: {
        list: {
            margin: [15, 15, 30, 15],
        },
        description: {
            margin: [15, 15, 30, 15],
        }
    },
})

export default ProductCard;
