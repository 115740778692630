import sample from '../../../assets/home/video.mp4'
import styleSchema from "../../../css/styleSchema";

import {createUseStyles} from "react-jss";
import React from "react";
import commonData from "../../../assets/data/commonData";
import icons from "../../../assets/icons";
import IconLinkBox from "../icon/IconLinkBox";
import Devider from "../Divider";
import {Popup} from "../Popup";
import ContactUsForm from "../ContactUsForm";
import typography from "../../../css/typography";


const HomeMainCard = () => {
    const content = {
        title: 'Получи доход от своей недвижимости с Kvartel Business',
        description: 'Сдавай свою недвижимость в Москве и МО вместе с управляющей компанией Kvartel Business.',
        description2: 'Мы увеличим доходность от сдачи жилья в два раз за счет полной автоматизации процесса.',
        video: sample,
    }
    return VideoCard(content)
}
export default HomeMainCard;
const VideoCard = (content) => {
    const classes = Style();

    return (
        <div className={classes.cardBlock}>
            <div className={`row ${classes.cardBlock} bgGreen`}>
                <div className='col-lg-5 col-md-12 mt-4 '>
                    <div className={`${classes.contentBlock}  `}>
                        <div className={`${classes.textBox}`}>
                            <div className={`row`}>
                                <h1 className={`${classes.title} mb-3`}>{content.title}</h1>
                            </div>
                            <div className={`row`}>
                                <div className={classes.descriptionBlock}>
                                    <div className={`${classes.description}`}>
                                        {content.description}
                                    </div>
                                    <div className={`${classes.description} mb-3`}>
                                        {content.description2}
                                    </div>
                                </div>
                            </div>
                            {/*contact us*/}
                            <div className={`row`}>
                                <div className={`fit`}>
                                    <Devider theme={'light'}/>
                                    <div className={`row`}>
                                        <div className={`fit ${classes.contactText}`}>Появились вопросы?</div>
                                        <div className={`fit m-2 ${classes.iconsBlock}`}>
                                            <IconLinkBox
                                                theme={{
                                                    color: styleSchema.white.color,
                                                    size: 32
                                                }}
                                                icon={icons.whatsapp}
                                                link={commonData.whatsapp}
                                            />
                                            <IconLinkBox
                                                theme={{
                                                    color: styleSchema.white.color,
                                                    size: 32
                                                }}
                                                icon={icons.telegram}
                                                link={commonData.supportTelegram}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Popup
                                            popupButton={'Оставить заявку'}
                                            popupTitle={'Оставить заявку'}
                                            content={<ContactUsForm/>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`col-lg-12 col-md-12 order-1 order-md-2 p-0 ${classes.videoBg}`}>
                    <div className={`${classes.videoContainer} h-100 `}>
                        <video className={`${classes.video}`} autoPlay loop muted playsInline>
                            <source src={sample} type='video/mp4'/>
                        </video>
                    </div>
                </div>

            </div>
        </div>
    )
}

const Style = () => {

    const useStyles = createUseStyles({
        cardBlock: {
            width: '100%',
            minHeight: 800,
            [`@media screen and ${styleSchema.mobScreen}`]: {
                minHeight: 'fit-content',
            },
        },
        contentBlock: {
            marginLeft: 40,
            [`@media screen and ${styleSchema.screen1181}`]: {
                padding: [0, 35],
                width: 'fit-content',
            },
            [`@media screen and ${styleSchema.tabScreen}`]: {
                padding: [0, 70],
            },
            [`@media screen and ${styleSchema.mobScreen}`]: {
                padding: [0, 20],
                marginLeft: 0,
            },
        },
        textBox: {
            [`@media screen and ${styleSchema.mobScreen}`]: {
                paddingBottom: 20,
            },
        },
        title: typography({color: styleSchema.white.color}).h1,
        descriptionBlock: {},
        description: {
            fontSize: 24,
            color: styleSchema.white.color,
            fontFamily: styleSchema.fontLight,
            [`@media screen and ${styleSchema.mobScreen}`]: {
                fontSize: 20,
            },
        },
        iconsBlock: {
            display: "flex",
            flexDirection: "row",
        },
        contactText: {
            fontSize: 16,
            color: styleSchema.white.color,
            fontFamily: styleSchema.fontBold,
            display: 'flex',
            alignItems: 'center',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                marginTop: 20,
            },
        },
        videoBg: {
            display: "contents",
        },
        videoContainer: {
            maxHeight: styleSchema.mainCardMaxHeight,
            zIndex: -2,
            position: "absolute",
            width: '100%',
            height: '100vh',
            left: 0,
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
            },
            [`@media screen and ${styleSchema.mobScreen}`]: {
                minHeight: 380,
            },
        },
        video: {
            objectFit: 'cover',
            maxHeight: styleSchema.mainCardMaxHeight,
            zIndex: -1,
            position: "relative",
            height: '100%',
            width: '100%',
        },
    })
    return useStyles()
}
