import React, {Component} from 'react';
import Footer from './Footer';
import Header from './templates/Header'

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="elfsight-app-898410e2-794c-494e-b52d-2da48603a0ea"></div>
                {this.props.children}
                <Footer/>
            </React.Fragment>
        )
    }
}
