import {createUseStyles} from "react-jss";
import React from "react";
import styleSchema from "../../css/styleSchema";

const Iframe = (props) => {
    const classes = Style();
    return (
        <div className={`${classes.sectionMap}`}>
            <iframe
                className={`${classes.iframe}`}
                src={props.mapLink}
                width={props.width}
                height={props.height}
                loading='lazy'
                allowFullScreen=''
                title={props.title}
            />
        </div>
    )
}

const Style = () => {
    const useStyles = createUseStyles({
        sectionMap: {
            width: '100%',
            height: '100%',
            paddingTop:'3rem',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                padding: '1rem',
            },
        },
        iframe: {
            width: '100%',
            height: '100%',
            minHeight: 300,
            background: '#CBCBCB',
            borderRadius: 25,
            filter: 'grayscale(1)',
            transition: '0.3s ease',
            '&:hover': {
                filter: 'grayscale(0)',
            },
        }
    })
    return useStyles()
}
export default Iframe
