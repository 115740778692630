import {Helmet} from "react-helmet";
import commonData from "../../assets/data/commonData";
import {contacts} from "../../assets/data/schemaOrg/contacts";
import React from "react";

export function ContactsHelmet() {
    const title = `${commonData.commonTitle}Контакты`
    const meta = {
        title: 'Контакты | Kvartel Business'
    }
    const description = `Свяжитесь с командой Kvartel, чтобы узнать о том как сдать квартиру посуточно`
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={meta.title}/>
            <meta name="description" content={description}/>
            {/*<meta name="keywords"
                  content="Kvartel Business, Квартель бизнес, контакты, связаться с нами, электронная почта, форма обратной связи"/>*/}
            <meta name="robots" content="index, follow"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`${commonData.prodURL}/contacts`}/>
            <meta property="og:image" content={commonData.logo}/>
            <script type={`application/ld+json`}>{JSON.stringify(contacts)}</script>
        </Helmet>
    )
}
