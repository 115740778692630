const tariffData = [
    {
        title: "Стандарт",
        price: '10%',
        description: 'Идеально подойдет для тех, кто хочет начать заниматься самостоятельно и иметь профессиональную поддержку консьержа.',
        details: [
            'Персональный менеджер',
            'Личный кабинет',
            'Размещение объявлений на 3-х каналах',
            'Ценообразование',
            'Коммуникация с Гостями и организация удаленного заезда',
            'Онлайн платежи',
            'Аналитика'
        ],
    },
    {
        title: "Стандарт Plus",
        price: '15%',
        description: 'Тариф предлагает комплексное управление вашей недвижимостью, начиная от бронирования и заканчивая размещением гостей.',
        details: [
            'Все преимущества тарифа Стандарт',
/*            'Личный кабинет',*/
            'Размещение объявлений на всех каналах продаж',
/*            'Ценообразование',
            'Верификация гостей',
            'Коммуникация с Гостями',*/
            'Организация уборки ',
            'Закупки хоз. принадлежностей',
            'Сниженные тарифы на подготоку объекта',
            'Установка умного замка',
            /*'Аналитика'*/
        ],
    },
    {
        title: "Премиум",
        price: '20%',
        description: 'Предлагает премиальный уровень управления вашим объектом с расширенным набором услуг и круглосуточной поддержкой.',
        details: [
            'Все преимущества тарифа Стандарт Plus',
           /* 'Персональный менеджер',
            'Личный кабинет',
            'Размещение объявлений на всех каналах продаж',
            'Ценообразование',
            'Верификация гостей',
            'Коммуникация с Гостями',*/
            'Организация уборки ',
            'Закупки хоз. принадлежностей',
            'Бесплатная подготовка объекта',
            'Бесплатная установка умного замка',
            'Поддержка 24/7',
           /* 'Аналитика'*/
        ],
    },
]
export default tariffData;
