import {createUseStyles} from "react-jss";
import React from "react";
import styleSchema from "../../css/styleSchema";
import icons from "../../assets/icons";
import commonData from "../../assets/data/commonData";


const ContactsData = (props) => {
    const classes = Style(props.theme);

    return (
        <div className="col-12">
            <div className={`row ${styleSchema.aos}`} data-aos={styleSchema.data_aos} data-aos-delay="200">
                <div className={`col-12`}>
                    <div className={classes.contactBlock}>
                        <span className={classes.contactItem}>Адрес:</span>
                        <div  className={`${classes.contactLink} ${classes.pointerNone}`}>
                            <i className={`${classes.iconBlock}`}>{icons.location}</i>
                            {commonData.address}
                        </div>
                    </div>
                </div>
                <div className={"col-12"} >
                    <div className={classes.contactBlock}>
                        <span className={classes.contactItem}>Телефон </span>
                        <a href={commonData.supportPhoneHref} className={`${classes.contactLink}`}>
                            <i className={`${classes.iconBlock}`}>{icons.phone}</i>
                            {commonData.supportPhone}
                        </a>
                    </div>
                </div>
                <div className={"col-12" } >
                    <div className={classes.contactBlock}>
                        <span className={classes.contactItem}>E-mail:</span>
                        <a href={commonData.supportEmailHref} className={`${classes.contactLink}`}>
                            <i className={`${classes.iconBlock}`}>{icons.email}</i>
                            {commonData.supportEmail}
                        </a>
                    </div>
                </div>
                <div className={"col-12" } >
                    <div className={classes.contactBlock}>
                        <span className={classes.contactItem}>Написать нам</span>
                        <div className={`row`}>
                            <a href={commonData.supportTelegram} className={`fit ${classes.contactLink}`}>
                                <i className={`${classes.iconBlock}`}>{icons.telegram}</i>
                                Telegram
                            </a>
                            <a href={commonData.whatsapp} className={`fit ${classes.contactLink}`}>
                                <i className={`${classes.iconBlock}`}>{icons.whatsapp}</i>
                                WhatsApp
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactsData
const Style = (theme) => {
    let textColor, iconColor;
    if (theme === 'light') {
        textColor = styleSchema.white.color
        iconColor = styleSchema.white.color
    } else {
        textColor = styleSchema.greenColorDark.color
        iconColor = textColor = styleSchema.greenColorDark.color
    }

    const useStyles = createUseStyles({
        contactBlock: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
        },
        contactItem: {
            fontSize: 16,
            color: textColor,
            // marginTop: 10,
            marginBottom: 5,
            fontFamily: styleSchema.fontMedium,
        },
        contactLink: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            color: textColor,
            transition: '0.2s ease',
            textDecoration: 'none',
            fontFamily: styleSchema.fontMedium,
            '&:hover': {
                color: styleSchema.olive.color,
            },

        },
        pointerNone: {
            pointerEvents: 'none',
        },
        iconBlock: {
            '& svg': {
                height: 20,
                width: 20,
                objectFit: 'contain',
                objectPosition: 'center',
                marginRight: 10,
                transition: '0.2s ease',
                color: iconColor,
                '&:hover': {
                    color: styleSchema.olive.color,
                },
            }
        }
    })
    return useStyles()
}
