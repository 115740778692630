import styleSchema from "./styleSchema";

export default function typography (props)  {

    return {
        bodySmall: {
            fontFamily: styleSchema.fontLight,
            fontSize: 12,
            lineHeight: 1.5,
            color: styleSchema.white.color,
        },
        bodyMedium: {},
        bodyLarge: {},
        h1: {
            color: props.color,
            fontSize: 40,
            fontFamily: styleSchema.fontBold,
            [`@media screen and ${styleSchema.mobScreen}`]: {
                fontSize: 30,
            },
        },
        h2: {
            fontSize: 40,
            textAlign: 'center',
            fontFamily: styleSchema.fontBold,
            color: props.color,
            margin: "auto",
            [`@media screen and ${styleSchema.mobScreen}`]: {
                fontSize: 30,
            },
            [`@media screen and ${styleSchema.tabScreen}`]: {
                marginTop: 50,
                marginBottom: 20,
                fontSize: 30,
            },
        },
        h3: {},
        h4: {},
        titleLarge: {
            fontSize: 24,
            fontFamily: styleSchema.fontBold,
            color: props.color,
            margin: "auto",
            [`@media screen and ${styleSchema.screenMax900}`]: {
                justifyContent: 'center',
            },
        },
    }
}
