import commonData from "../../assets/data/commonData";
import lockKvartel from "../../assets/lock/lockKvartel.webp";
import {Helmet} from "react-helmet";
import React from "react";
import {locksList} from "../../assets/data/schemaOrg/locksList";

export default function LocksHelmet(){
    const title=`${commonData.commonTitle}Магазин умных замков`
    return(
        <Helmet>
            <meta name="title" content={title}/>
            <title>{title}</title>
            <meta name="description"
                  content={`Cистема удаленного заселения гостей для посуточной недвижимости ${commonData.commonTitleSm}.`}/>

            {/**/}
            <meta property="og:title" content={`Магазин умных замков ${commonData.commonTitleSm}`}/>
            <meta property="og:description" content="Cистема удаленного заселения гостей для посуточной недвижимости."/>
            <meta property="og:type" content="website"/>
            <meta property="og:site_name"
                  content={`${commonData.commonTitleSm} | Сервис онлайн заселения гостей в посуточные квартиры`}/>
            <meta property="og:url" content={`${commonData.prodURL}/equipment`}/>
            <meta property="og:image"
                  content={lockKvartel}/>

            <script type={`application/ld+json`}>{JSON.stringify(locksList)}</script>
        </Helmet>
    )
}
