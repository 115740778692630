import React from "react"
import logo from '../assets/logo/greenLogoFull_w300.png'
import {createUseStyles} from "react-jss";
import styleSchema from "../css/styleSchema";
import commonData from "../assets/data/commonData";
import Divider from "./templates/Divider";
import ContactsData from "./templates/ContactsData";
import IconLinkBox from "./templates/icon/IconLinkBox";
import typography from "../css/typography";


const useStyles = createUseStyles({
    footerContent: {
        padding: [15, 40],
        backgroundColor: styleSchema.greenColorDark.color,
    },
    contactData: {
        [`@media screen and ${styleSchema.mobScreen}`]: {
            // padding: [0, 45]
        },
        [`@media screen and ${styleSchema.tabScreen}`]: {
            display: 'flex',
            flexDirection: 'row',
            width: 'fit-content',
            padding: [20, 0],
        },
    },
    logoBlock: {
        display: 'flex',
        flexDirection: "column",
    },
    logoText: {
        fontFamily: styleSchema.fontBold,
        fontSize: 35,
        color: styleSchema.white.color,
        [`@media screen and ${styleSchema.mobScreen}`]: {
            fontSize: 30,
        }
    },
    logo: {
        height: 25,
        [`@media screen and ${styleSchema.mobScreen}`]: {
            height: 20,
        }
    },
    copyright: typography({}).bodySmall
})

const Footer = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <footer>
                <section className={`section`}>
                    <div className={`row m-0 ${classes.footerContent}`}>
                        <div className={`${classes.logoBlock} col-lg-4`}>
                            <div className={`row p-0 m-0`}>
                                <a href="/" className={` align-items-baseline d-flex text-decoration-none`}>
                                    <img className={classes.logo} src={logo} alt="logo"/>
                                    <div className={classes.logoText}>Business</div>
                                </a>
                            </div>
                            <div className={`row p-0 m-0`}>
                                <IconsLinks title={''}
                                            links={commonData.footerFollowUsBlock.links}/>
                                <div className={`${classes.copyright} `}>
                                    * - Meta Platforms Inc. - признана экстремистской, ее деятельность запрещена на
                                    территории России.
                                </div>
                            </div>
                            <div className={`row p-0  m-3`}>
                                <Divider theme={'light'}/>
                            </div>
                            <div className={`row p-0 m-0 `}>
                                <div className={`d-none d-sm-block ${classes.copyright}`}>
                                    {`© Copyright 2014 - ${new Date().getFullYear()} Kvartel Business. Все права защищены.`}
                                </div>
                            </div>
                        </div>


                        <div
                            className={`col-lg-3 col-md-12 col-sm-12  ${classes.contactData} ${styleSchema.aos}`}
                            data-aos={styleSchema.data_aos}
                            data-aos-delay="200"
                        >
                            <IconsLinks title={commonData.footerAppDownloadBlock.title}
                                        links={commonData.footerAppDownloadBlock.links}/>
                            <IconsLinks title={commonData.KBAppDownloadData.title}
                                        links={commonData.KBAppDownloadData.links}/>
                        </div>
                        <div className={`col-lg-4 col-md-12 col-sm-12 d-grid ${classes.contactData}`}>
                            <ContactsData theme={'light'}/>
                        </div>
                    </div>
                </section>
            </footer>
        </React.Fragment>
    )
}
export default Footer;

const IconsLinks = (props) => {
    const css = createUseStyles({
        iconsLinks: {},
        footerTitle: {
            fontFamily: styleSchema.fontMedium,
            color: styleSchema.white.color,

        },
        iconsBlock: {
            display: "flex",
            flexDirection: "row",
        },
    })
    const classes = css()
    return (
        <div className={classes.iconsLinks}>
            <div className={`mb-2 ${classes.footerTitle}`}>{props.title}</div>
            <div className={`fit m-2 ${classes.iconsBlock}`}>
                {props.links.map((item, index) => {
                    return (
                        <IconLinkBox
                            key={index}
                            theme={{
                                color: styleSchema.white.color,
                                size: 24
                            }}
                            link={item.link}
                            icon={item.icon}
                        />
                    )
                })}
            </div>
        </div>
    )
}
