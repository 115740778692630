import React, {useEffect, useState} from "react";
import icons from "../../../assets/icons";
import {createUseStyles} from "react-jss";
import {useLocation} from "react-router-dom";
import styleSchema from "../../../css/styleSchema";

const menuToggleButton = props => {
    let classes = Style();
    /** change header style on scroll*/
        // eslint-disable-next-line react-hooks/rules-of-hooks
    const [small, setSmall] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setSmall(window.scrollY > 200)
            );
        }
    }, []);

    return (
        <button className={small ? `${classes.stickyToggleButton} ${classes.toggleButton}` : classes.toggleButton}
                onClick={props.click}>
            <i className={classes.toggleIcon}>{icons.bars}</i>
        </button>
    )
}

const Style = () => {
    let toggleColor
    if (useLocation().pathname === '/') {
        toggleColor = styleSchema.white.color;
    } else {
        toggleColor = styleSchema.mainGreyColor;
    }

    const useStyles = createUseStyles({
            toggleButton: {
                height: 50,
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: toggleColor,
                '&:focus': {
                    outline: 'none',
                },
            },
            stickyToggleButton: {
                color: styleSchema.mainGreyColor
            },
            toggleIcon: {
                '& svg': {
                    height: 25,
                    color:styleSchema.greenColorDark.color
                }
            }
        }
    )
    return useStyles()
}
export default menuToggleButton;
