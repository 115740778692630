import React, {Component} from "react";
import styleSchema from "../css/styleSchema";
import ContactUsForm from "../components/templates/ContactUsForm";
import ContactsData from "../components/templates/ContactsData";
import WhiteBoxTextCenter from "../components/templates/WhiteBoxTextCenter";
import commonData from "../assets/data/commonData";
import typography from "../css/typography";
import MapLocation from "../components/component/MapLocation";
import {Container} from "../components/component/Container";
import {ContactsHelmet} from "../components/helmet/ContactsHelmet";

export default class Contacts extends Component {
    componentDidMount() {
        document.title = `${commonData.commonTitle}Контакты`
    }

    render() {
        return (
            <div className="container">
               <ContactsHelmet/>
                <Container>
                    <div className="row justify-content-center ">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <WhiteBoxTextCenter
                                title={<h1
                                    style={typography({color: styleSchema.greenColorDark.color}).h2}>Наши
                                    контакты</h1>}
                                link={''}
                                content={
                                    <ContactsData/>
                                }/>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className={` ${styleSchema.aos}`} data-aos={styleSchema.data_aos}
                                 data-aos-delay="100">
                                <div className={`row m-0 p-0`}>
                                    <WhiteBoxTextCenter
                                        title={
                                            <h2 style={typography({color: styleSchema.greenColorDark.color}).h2}>
                                                Возникли вопросы?
                                            </h2>}
                                        link={''}
                                        content={
                                            <ContactUsForm theme={'light'}/>
                                        }/>
                                </div>
                            </div>
                        </div>
                        <MapLocation/>
                    </div>
                </Container>
            </div>
        )
    }
}

