import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock, faMobile, faCalendarCheck,
    faDoorOpen, faCreditCard, faAddressCard,
    faHandHoldingDollar, faPersonWalkingLuggage, faHeadset, faCameraRetro, faScrewdriverWrench, faBuildingLock,
    faFileSignature, faHouseSignal, faChartLine,
    faPhone, faLocationDot, faEnvelope,
    faRuble,
    faBars, faXmark,
    faWifi,
    faCheck, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import {faGooglePlay, faAppStoreIos, faInstagram, faFacebook, faChrome, faWhatsapp, faTelegram} from "@fortawesome/free-brands-svg-icons";
import checkCircleCustom from './icon-circle-check.svg'

const icons = {
    //footer
    googlePlay: <FontAwesomeIcon icon={faGooglePlay}/>,
    appStore: <FontAwesomeIcon icon={faAppStoreIos}/>,
    instagram: <FontAwesomeIcon icon={faInstagram}/>,
    facebook: <FontAwesomeIcon icon={faFacebook}/>,
    web: <FontAwesomeIcon icon={faChrome}/>,

    //contacts
    phone: <FontAwesomeIcon icon={faPhone}/>,
    location: <FontAwesomeIcon icon={faLocationDot}/>,
    email: <FontAwesomeIcon icon={faEnvelope}/>,

    //home Advantages
    clock: <FontAwesomeIcon icon={faClock}/>,
    mobile: <FontAwesomeIcon icon={faMobile}/>,
    calendar: <FontAwesomeIcon icon={faCalendarCheck}/>,

    //home HowItWorks
    doorOpen: <FontAwesomeIcon icon={faDoorOpen}/>,
    creditCard: <FontAwesomeIcon icon={faCreditCard}/>,
    addressCard: <FontAwesomeIcon icon={faAddressCard}/>,

    //franchise Advantages
    holdingDollar: <FontAwesomeIcon icon={faHandHoldingDollar}/>,
    personWalkingLuggage: <FontAwesomeIcon icon={faPersonWalkingLuggage}/>,
    supportIcon: <FontAwesomeIcon icon={faHeadset}/>,
    cameraIcon: <FontAwesomeIcon icon={faCameraRetro}/>,
    serviceIcon: <FontAwesomeIcon icon={faScrewdriverWrench}/>,
    buildingIcon: <FontAwesomeIcon icon={faBuildingLock}/>,

    // franchise Conditions
    signatureIcon: <FontAwesomeIcon icon={faFileSignature}/>,
    houseIcon: <FontAwesomeIcon icon={faHouseSignal}/>,
    reportIcon: <FontAwesomeIcon icon={faChartLine}/>,

    //franchise lock
    ruble: <FontAwesomeIcon icon={faRuble}/>,

    //menu
    bars: <FontAwesomeIcon icon={faBars}/>,
    close: <FontAwesomeIcon icon={faXmark}/>,
    wifi: <FontAwesomeIcon icon={faWifi}/>,
    whatsapp:<FontAwesomeIcon icon={faWhatsapp}/>,
    telegram:<FontAwesomeIcon icon={faTelegram}/>,

    //tariff
    check: <FontAwesomeIcon icon={faCheck}/>,
    checkCircle: <FontAwesomeIcon icon={faCheckCircle}/>,
    checkCircle2: checkCircleCustom,
}

export default icons;
