import logo from '../../logo/logoDark.svg'
import commonData from "../commonData";
export const contacts={
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "/contacts"
    },
    "publisher": {
        "@type": "LocalBusiness",
        "@id": "/",
        "name": "Kvartel Business",
        "logo": `${logo}`,
        "telephone": commonData.supportPhone,
        "email": commonData.supportEmail,
        "sameAs": [
            commonData.instagram,
            commonData.facebook
        ],
        "url": "/",
        "image": logo,
        "description": commonData.description,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": commonData.streetAddress,
            "addressLocality": commonData.addressLocality,
            "addressRegion": commonData.addressRegion,
            "postalCode": commonData.zipcode
        }
    }
}
