import styleSchema from "../../css/styleSchema";
import Iframe from "../templates/iframe";
import commonData from "../../assets/data/commonData";
import React from "react";

export default function MapLocation(){
    return (
        <div className={`pb-5 ${styleSchema.aos}`}
             data-aos={styleSchema.data_aos} data-aos-delay="500">
            <Iframe
                mapLink={commonData.mapLink}
                width={"600"}
                height={"450"}
                title={commonData.streetAddress}
            />
        </div>
    )
}
