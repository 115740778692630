const lightGreenColor = '#009032';
const lightGreenColorRbg = '0, 144, 50';

const mainGreyColor = '#585858';
const lightGrey = '#8c8c8c';

const greenColorDark = '#212725';
const greenColorDarkRBG = '33, 39, 37';

const greenMedium = '#255a39';
const greenMediumRBG = '37, 90, 57'

const white = '#EBE7DE';
const whiteRBG = '235,231,222'

const olive = '#64785A'
const oliveRBG = '100,120,90'
const darkOlive = '#506048'

const violet = '#7250FF';

const styleSchema = {

    h2Grey: "text-uppercase color-gray",
    h2: "text-uppercase",
    fontBold: 'ChronicaPro-Bold',
    fontMedium: 'ChronicaPro-Medium',
    fontLight: 'ChronicaPro-SemiLight',
    mainGreyColor: mainGreyColor,

    mobScreen: '(max-width: 767px)',
    tabScreen: '(min-width: 768px) and (max-width: 1139px)',
    screen1181: '(max-width: 1181px)',
    screenMax900: '(max-width: 900px)',
    aos: ' aos-init aos-animate',
    data_aos: "fade-up",

    boxShadow: '0 15px 20px rgb(0 0 0 / 10%)',

    iconBoxColor:'#E4E0D9',

    greenColorDark: {
        colorRBG: greenColorDarkRBG,
        color: greenColorDark,
        color10: `rgba(${greenColorDarkRBG}, 0.1)`,
        color30: `rgba(${greenColorDarkRBG}, 0.3)`,
        color70: `rgba(${greenColorDarkRBG}, 0.7)`,
        boxShadow: `0 15px 20px rgb(${greenColorDarkRBG}, 0.3)`,
        border: `1px solid rgba(${greenColorDarkRBG}, 0.3)`,
    },
    olive:{
        color:olive,
        colorRBG:oliveRBG,
        darkOlive:darkOlive,
        boxShadow:`0 3px 5px rgb(${oliveRBG}, 0.3)`,
        border: `1px solid rgba(${oliveRBG}, 0.3)`,
        color70: `rgba(${oliveRBG}, 0.7)`,
    },
    greenMedium: {
        colorRBG: greenMediumRBG,
        color: greenMedium,
        color10: `rgba(${greenMediumRBG}, 0.1)`,
        color30: `rgba(${greenMediumRBG}, 0.3)`,
        color70: `rgba(${greenMediumRBG}, 0.7)`,
        boxShadow: `0 15px 20px rgb(${greenMediumRBG}, 0.3)`,
        border: `1px solid rgba(${greenMediumRBG}, 0.3)`,
    },
    white: {
        colorRBG: whiteRBG,
        color: white,
        color10: `rgba(${whiteRBG}, 0.1)`,
        color30: `rgba(${whiteRBG}, 0.3)`,
        color70: `rgba(${whiteRBG}, 0.7)`,
        boxShadow: `0 15px 20px rgb(${whiteRBG}, 0.3)`,
        border: `1px solid rgba(${whiteRBG}, 0.3)`,
    },

    lightGrey: lightGrey,
    lightGreenColor: {
        colorRBG: lightGreenColorRbg,
        color: lightGreenColor,
        color10: `rgba(${lightGreenColorRbg}, 0.1)`,
        color30: `rgba(${lightGreenColorRbg}, 0.3)`,
        color70: `rgba(${lightGreenColorRbg}, 0.7)`,
        boxShadow: `0 15px 20px rgb(${lightGreenColorRbg}, 0.3)`,
        border: `1px solid rgba(${lightGreenColorRbg}, 0.3)`,
    },

    violetColor: {
        color: violet,
    },

    mainCardMaxHeight: 800,
    miHMainCard: 600,
}
export default styleSchema;
