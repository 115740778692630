import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";

const Devider = (props) => {
    const classes = Style(props.theme);
    return (
        <div className={`${classes.dividerTop} ${classes.divTransparent}`}></div>
    )
}

const Style = (theme) => {
    let color = ''
    if (theme === 'light') {
        color = styleSchema.white.colorRBG
    }else {color=styleSchema.greenColorDark.colorRBG}
    const useStyles = createUseStyles({
        dividerTop: {
            position: "relative",
            height: 1,
        },
        divTransparent:{
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '5%',
                right: '5%',
                width: '90%',
                height: 1,
                backgroundImage: `linear-gradient(to right, transparent, rgb(${color}), transparent)`,
            }
        }
    })
    return useStyles()
}
export default Devider
