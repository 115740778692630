import styleSchema from "../../css/styleSchema";
import {createUseStyles} from "react-jss";
import React from "react";

const CardBox = (props) => {
    const classes = Style();
    return (
        <div className={`${classes.box}`} key={props.index}>
            {props.content}
        </div>
    )
}

const Style = () => {
    const useStyles = createUseStyles({
        box: {
            padding:  [20],
            boxShadow: styleSchema.olive.boxShadow,
            background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.35) 21.75%, rgba(255, 255, 255, 0) 100%)',
            borderRadius: 40,
            height: '100%',
        },
    })
    return useStyles()
}
export default CardBox
