import React, {Component} from "react";
import ProductCard from "../components/templates/ProductCard";
import icons from "../assets/icons";
import BenefitsBlock from "../components/templates/BenefitsBlock";
import equipmentData from "../assets/data/equipmentData";
import WhiteBoxTextLeft from "../components/templates/WhiteBoxTextLeft";
import styleSchema from "../css/styleSchema";
import {createUseStyles} from "react-jss";
import commonData from "../assets/data/commonData";
import typography from "../css/typography";
import LocksHelmet from "../components/helmet/LocksHelmet";

const blockTitle = 'Оборудование';
const contactText = 'Хотите приобрести оборудование?';

export default class Lock extends Component {
    static displayName = Lock.name;

    componentDidMount() {
        document.title = `${commonData.commonTitle}Оборудование`
    }

    render() {
        return (
            <div className="container">
                <LocksHelmet/>
                <h1 className={`mt-5 text-center ${typography({color: styleSchema.greenColorDark.color}).h1}`}>
                    Наше оборудование
                </h1>
                <Products/>
                <div className="mb-5">
                    <WhiteBoxTextLeft
                        title={<h2
                            style={typography({color: styleSchema.greenColorDark.color}).h2}>{blockTitle}</h2>}
                        contactText={contactText}
                        bg={false}
                        content={<Description/>}
                    />
                    <div className={`mt-4`}>
                        <MainCardLock/>
                    </div>
                </div>
            </div>
        )
    }
}

const MainCardLock = () => {
    const itemData = [
        {
            itemTitle: '',
            itemDescription: 'Наше мобильное приложение позволит вам отследить статус замка, закрыт он или нет, а также вы сможете увидеть время, в которое он был закрыт/открыт.',
            itemIcon: icons.mobile,
        },
        {
            itemTitle: '',
            itemDescription: 'Подключив замок к шлюзу, вы получите возможность удаленно открыть замок, удалять/изменять существующие пароли.',
            itemIcon: icons.wifi,
        },
        {
            itemTitle: '',
            itemDescription: 'Предоставляем услуги установки и настройки оборудования. Гарантийное обслуживание - 1 год.',
            itemIcon: icons.serviceIcon,
        }
    ]
    return BenefitsBlock('', '', itemData)
}

const Products = () => {
    return ProductCard(equipmentData)
}

const Description = () => {
    const classes = Style();

    return (
        <div className={`${classes.descriptionBlock}`}>
            <div className={`mb-3`}>В посуточной аренде квартир электронные замки могут предоставить несколько ключевых
                преимуществ, которые сделают пребывание гостей более удобным и безопасным.
            </div>
            <div className={`mb-3`}>
                Легкость Регистрации и Въезда: Гости могут получить доступ к квартире без физического ключа. Электронные
                замки позволяют отправлять временные коды или использовать мобильные приложения для открытия двери,
                упрощая процесс регистрации и въезда.
            </div>
            <div className={`mb-3`}>
                Гибкость в Управлении: Арендодатель может предоставлять гостям временный код доступа, который
                автоматически истекает после завершения аренды. Это позволяет гибко управлять доступом и обеспечивать
                безопасность.
            </div>
            <div className={`mb-3`}>
                История Доступа: Электронные замки сохраняют историю доступа, что может быть полезно для арендодателей.
                Они могут отслеживать, когда гости входили и выходили из квартиры, что добавляет дополнительный уровень
                безопасности.
            </div>
            <div className={`mb-3`}>
                Безопасность и Удобство: Гости могут чувствовать себя более безопасно, зная, что доступ к квартире
                контролируется электронным замком. Кроме того, они избегают потери физических ключей, что обеспечивает
                дополнительное удобство.
            </div>
            <div className={`mb-3`}>
                Удаленное Управление: Арендодатель может управлять замком удаленно, открывая и закрывая дверь через
                мобильное приложение. Это может быть полезно, например, для предоставления временного доступа
                клининговой службе.
            </div>
            <div className={`mb-3`}>
                Интеграция с Платформой Хоумшеринг Kvartel: Электронный замок Kvartel интегрирован с платформой
                посуточной аренды Kvartel, что позволяет эффективно контролировать процессы регистрации и выезда гостей.
                А так же открывает дополнительный канал бронирований.
            </div>
            <div className={`mb-3`}>
                Оптимизация Общего Опыта: Электронные замки добавляют современный и инновационный элемент в общий опыт
                пребывания гостей, что может повысить уровень удовлетворенности и востребованность квартиры на рынке
                посуточной аренды.
            </div>
            <div className={`mb-3`}>
                Применение электронных замков в посуточной аренде обеспечивает безопасный, удобный и эффективный процесс
                управления доступом для как арендодателей, так и гостей.
            </div>
        </div>
    )
}
const Style = () => {

    const useStyles = createUseStyles({
        descriptionBlock: {
            fontFamily: styleSchema.fontMedium,
            fontSize: 16,
            color: styleSchema.greenColorDark.color,
            marginLeft: 40,
            width: 'fit-content',
            [`@media screen and ${styleSchema.screenMax900}`]: {
                margin: [0, 40],
            },
            [`@media screen and ${styleSchema.mobScreen}`]: {
                margin: [0, 25],
                fontFamily: styleSchema.fontLight,
            },
        },

    })
    return useStyles()
}
