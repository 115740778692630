import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from '../../css/styleSchema'
import IconBox from "./icon/IconBox";
import typography from "../../css/typography";

const BenefitsBlock = (blockTitle, blockDescription, itemData, boolean) => {

    const classes = Style(boolean);

    return (
        <div className={`row m-0 ${classes.rowCenter}`}>
            <div className="col-lg-11">
                <div className={styleSchema.aos} data-aos={styleSchema.data_aos}>
                    <h2 className={classes.benefitsTitle}>{blockTitle}</h2>
                    <div className={classes.benefitsDescription}>{blockDescription}</div>
                </div>
                <div className="section-content">
                    <div className={classes.benefitsContainer}>
                        <div className="row m-0">
                            {itemData.map((item, index) => {
                                return (
                                    <div className={`col-md-4 col-xl-4 p-0`} key={index}>
                                        <div
                                            className={`${classes.benefitsBlock} ${styleSchema.aos}`}
                                            data-aos={styleSchema.data_aos}
                                            data-aos-delay="200"
                                        >
                                            <div className={classes.blockIcon}>
                                                <IconBox icon={item.itemIcon}/>
                                            </div>
                                            <div className={`mt-4 ${classes.itemText}`}>
                                                <div className={`${classes.blockTitle}`}>{item.itemTitle}</div>
                                                <div
                                                    className={`mt-2 ${classes.blockDescription}`}>{item.itemDescription}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BenefitsBlock;

const Style = () => {
    let borderColor = styleSchema.greenColorDark.border;
    let textColor = styleSchema.white.color;

    const useStyles = createUseStyles({
        rowCenter: {
            display: 'flex',
            justifyContent: 'center',
        },
        benefitsTitle: typography({color: textColor}).h2,
        benefitsDescription: {
            color: textColor,
            textAlign: 'center',
            fontSize: 30,
            fontFamily: styleSchema.fontMedium,
            marginTop: 20,
            marginBottom: 20,
            [`@media screen and ${styleSchema.mobScreen}`]: {
                fontSize: 20,
            },
        },
        benefitsContainer: {
            border: borderColor,
            boxShadow: styleSchema.boxShadow,
            borderRadius: 40,
            overflow: 'hidden'
        },
        benefitsBlock: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: [50, 30],
            borderBottom: borderColor,
            borderRight: borderColor,
            height: '100%',
            backgroundColor: styleSchema.olive.darkOlive,
            [`@media screen and ${styleSchema.tabScreen}`]: {
                padding: [30, 15],
            },
            [`@media screen and ${styleSchema.screen1181}`]: {
                padding: [30, 15],
                fontSize: 15
            },
        },
        itemText: {
            maxWidth: 350,
            textAlign: 'center',
        },
        blockTitle: {
            textTransform: 'uppercase',
            fontFamily: styleSchema.fontBold,
            color: textColor,
            fontSize: 16,
            [`@media screen and ${styleSchema.tabScreen}`]: {
            },
            [`@media screen and ${styleSchema.screen1181}`]: {
            },
        },
        blockDescription: {
            color: textColor,
            fontFamily: styleSchema.fontLight,
            fontSize: 16,
            [`@media screen and ${styleSchema.tabScreen}`]: {
                padding: 0,
            },
        },
    })

    return useStyles()
}
