import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import styleSchema from "../css/styleSchema";
import commonData from "../assets/data/commonData";
import typography from "../css/typography";


const NotFoundPage = () => {
    const classes = useStyles();
    const title = `${commonData.commonTitle}404 Нет такой страницы`

    useEffect(() => {
        document.title = title
    }, []);

    return (
        <div className={`${classes.notFoundBox}`}>
            <div className={`row ${classes.textBox}`}>
                <div className={`${classes.text}`}>Ошибка 404. Нет такой страницы</div>
                <div className={`row justify-content-center mt-3`}><a href={`/`} className={`${classes.mainlink}`}>На
                    главную</a></div>
            </div>
            <i className={classes.img}>
                <svg width="1920" height="495" viewBox="0 0 1920 495" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1920 494.5H0V177.285L953.44 0L1920 177.285V494.5Z" fill="#64785A"/>
                </svg>
            </i>
        </div>
    );
};

export default NotFoundPage;

const useStyles = createUseStyles({
    notFoundBox: {
        padding: [100, 0, 0, 0],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: [20, 0, 0, 0],
        },
        [`@media screen and ${styleSchema.tabScreen}`]: {
            padding: [40, 0, 0, 0],
        },
    },
    img: {
        objectFit: 'cover',
        display: 'block',
        '& svg': {
            width: '100%',
            height: '100%',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                height: '100%',
                width: 'auto',
            },
            [`@media screen and ${styleSchema.tabScreen}`]: {
                height: '100%',
                width: 'auto',
            },
        }
    },
    textBox: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        position: "absolute",
        width: '100%',
    },
    text: typography({color:styleSchema.white.color}).h1,
    mainlink: {
        color: styleSchema.white.color,
        padding: 10,
        fontFamily: styleSchema.fontMedium,
        fontSize: 16,
        textDecoration: "none",
        border: styleSchema.white.border,
        width: "fit-content",
        '&:hover': {
            color: styleSchema.white.color,
            boxShadow: styleSchema.boxShadow,
        }
    },
});
