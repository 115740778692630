import React from "react";
import icons from "../../../assets/icons";
import BenefitsBlock from "../BenefitsBlock";
import {createUseStyles} from "react-jss";
import styleSchema from "../../../css/styleSchema";
import violetLogo from '../../../assets/logo/logo-light-violet.png'
import IconLinkBox from "../icon/IconLinkBox";
import commonData from "../../../assets/data/commonData";
import imgLeft from '../../../assets/home/Image (1).png'
import imgMid from '../../../assets/home/Image (3).png'
import imgRight from '../../../assets/home/Image (2).png'


const Advantages = () => {
    const classes = Style();

    return (
        <section className="section  bg-olive">
            <div className={`row m-0 `}>
                <div className={`col-lg-6 col-md-12 col-sm-12 p-0 `}>
                    <div className={`${classes.imhBox} ${classes.blockImage} mt-5`}>
                        <div className={`${styleSchema.aos}`} data-aos="fade-right" data-aos-duration="1500"
                             data-aos-easing="linear">
                            <img alt={`hero image1`} className={` ${classes.imgSize} ${classes.imgLeft}`} src={imgLeft}/>
                        </div>
                        <div className={`${styleSchema.aos}`} data-aos="fade-left" data-aos-duration="1500"
                             data-aos-easing="linear">
                            <img alt={`hero image2`} className={`  ${classes.imgSize} ${classes.imgRght} `} src={imgRight}/>
                        </div>

                        <div className={` ${styleSchema.aos}`} data-aos={styleSchema.data_aos}
                             data-aos-duration="1500" data-aos-easing="linear">
                            <img alt={`hero image3`} className={`  ${classes.imgSize} ${classes.imgMid}`} src={imgMid}/>
                        </div>
                    </div>

                    <div className={`${classes.logoBox}`}>
                        <div className={`${classes.logoIngBox}`}>
                            <img alt={`Kvartel logo`} className={`${classes.logo}`} src={violetLogo}/>
                        </div>
                        <div className={`${classes.logoText}`}>Хоумшеринг - Скачивай,
                            Заселяйся!
                        </div>
                        <div className={`${classes.iconsBox} mt-4`}>
                            {commonData.footerAppDownloadBlock.links.map((link, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <IconLinkBox theme={'violet'} icon={link.icon} link={link.link}/>
                                    </React.Fragment>
                                )
                            })}

                        </div>
                    </div>
                </div>
                <div className={`col-lg-6 col-md-12 col-sm-12 p-0`}>
                    <div className={`row m-0`}>
                        <div className={`${classes.conditionsBlock}`}>
                            <div className={classes.relative}>
                                {BenefitsBlock(blockTitle, blockDescription, itemData)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Advantages;

const blockTitle = 'Наши преимущества';
const blockDescription = ''
const itemData = [
    {
        itemTitle: 'Высокая доходность',
        itemDescription: 'Краткосрочная аренда обеспечивает в Москве и МО высокий возврат инвестиций, в 2-3 раза превышающий стандартный ROI.',
        itemIcon: icons.holdingDollar
    },
    {
        itemTitle: 'Проверка гостей',
        itemDescription: 'При заселении через приложение Kvartel каждый гость проходит идентификацию личности, обеспечивая безопасность. Заключаем договор аренды.',
        itemIcon: icons.personWalkingLuggage
    },
    {
        itemTitle: 'Консьерж сервис 24/7',
        itemDescription: 'Наша служба доступна 24/7 и готова прибыть по адресу квартиры для оперативного решения любых вопросов.',
        itemIcon: icons.supportIcon
    },
    {
        itemTitle: 'Профессиональная фотография',
        itemDescription: 'Наши профессиональные фотографы и дизайнеры выгодно подчеркнут каждую деталь вашего дома.',
        itemIcon: icons.cameraIcon
    },
    {
        itemTitle: 'Уборка и Поддержка',
        itemDescription: 'Мы возьмем на себя организацию всего базового обслуживания: уборка, поломка бытовой техники, проведение мелкого ремонта.',
        itemIcon: icons.serviceIcon
    },
    {
        itemTitle: 'Умный замок',
        itemDescription: 'Мы установим все необходимое оборудование для обеспечения максимальной безопасности вашей недвижимости.',
        itemIcon: icons.buildingIcon
    }
]

const Style = () => {
    const useStyles = createUseStyles({
        blockImage: {
            objectFit: 'cover',
            width: 'fit-content',
            margin: "auto",
            //width: '100%',
          //  height: '100%',
        },
        conditionsBlock: {
            padding: [50, 0],
            [`@media screen and ${styleSchema.tabScreen}`]: {
                conditionsBlock: {
                    padding: 10,
                }
            },
        },
        relative: {
            position: 'relative',
        },
        logoBox: {
            maxWidth: 500,
            margin: "auto",
            marginTop: '30em',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                marginTop: '20em',
                maxWidth: 380,
            },
        },
        logo: {
            maxHeight: 70,
            objectFit: "contain",
        },
        logoText: {
            fontSize: 24,
            fontFamily: styleSchema.fontMedium,
            color: styleSchema.white.color,
            textAlign: "center",
        },
        imhBox: {
            display: 'flex',
            justifyContent: 'center',
            transform: 'translateX(-5rem)',
            paddingTop: '3rem',
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
            },
            [`@media screen and ${styleSchema.mobScreen}`]: {
                paddingTop: 0,
                transform: 'translateX(-4rem)',
            },
        },
        imgSize: {
            maxHeight: 400,
            objectFit: "contain",
            position: 'absolute',
            display: 'block',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                maxHeight: 240,
            },
        },
        imgLeft: {
            transform: 'translateX(-10rem) translateY(2rem) rotate(-11deg)',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                transform: 'translateX(-5rem) translateY(2rem) rotate(-11deg)',
            },
        },
        imgMid: {},
        imgRght: {
            transform: 'translateX(10rem) translateY(2rem) rotate(11deg)',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                transform: 'translateX(5rem) translateY(2rem) rotate(11deg)',
            },
        },
        logoIngBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        iconsBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
        },
    })
    return useStyles()
}

