import {createUseStyles} from "react-jss";
import styleSchema from "../../../css/styleSchema";
import React from "react";

const Backdrop = props => {
    let classes = useStyles();

    return (
        <div className={classes.backdrop} onClick={props.click}/>
    )
};

const useStyles = createUseStyles({
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: styleSchema.greenColorDark.color70,
        zIndex: 100,
    }
})

export default Backdrop;
