import {createUseStyles} from "react-jss";
import React from "react"
import styleSchema from "../../../css/styleSchema";

const IconBox = props => {
    const classes = Style();

    return (
        <div className={classes.iconBox}>
            <i className={classes.icon}>
                {props.icon}
            </i>
        </div>
    )
}


const Style = () => {
    const useStyles = createUseStyles({
        iconBox: {
            backgroundColor: styleSchema.iconBoxColor,
            borderRadius: 30,
            width: 106,
            height: 106,
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
        },
        icon: {
            '& svg': {
                height: 55,
                color: styleSchema.olive.color,
            },
        },
    })
    return useStyles()
}

export default IconBox
