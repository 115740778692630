import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";
import Divider from "./Divider";
import IconLinkBox from "./icon/IconLinkBox";
import icons from "../../assets/icons";
import commonData from "../../assets/data/commonData";

const WhiteBoxTextCenter = (props) => {
    const classes = Style(props.bg);

    return (
        <div className={`container`}>
            <div className={`row m-0 justify-content-center`}>
                <div className={`col-11 ${classes.contentBox} ${classes.contentBoxBg} `}>
                    <div className={`col-lg-3 col-md-12 col-sm-12 ${classes.textBox}`}>
                        <div className={`row mb-3`}>{props.title}</div>
                        <Divider theme={'dark'}/>
                        <div className={`row mb-3 mt-3 ${classes.description} `}>{props.description}</div>
                        <div className={`row mb-3`}>
                            <div className={`col fit ${classes.contactText}`}>{props.contactText}</div>
                            <div className={`fit m-2 ${classes.iconsBlock}`}>
                                <IconLinkBox
                                    theme={{
                                        color: styleSchema.greenColorDark.color,
                                        size: 32
                                    }}
                                    icon={icons.whatsapp} link={commonData.whatsapp}/>
                                <IconLinkBox
                                    theme={{
                                        color: styleSchema.greenColorDark.color,
                                        size: 32
                                    }}
                                    icon={icons.telegram} link={commonData.supportTelegram}/>
                            </div>
                        </div>
                    </div>
                    <div className={`col-lg-9 col-md-12 col-sm-12 ${classes.contentBlock}`}>
                        <div className={`row justify-content-center`}>
                            {props.content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Style = (bg) => {
    let contentBoxBg
    if (bg === false) {
        contentBoxBg = {
            //width: "fit-content",
            padding: [40, 50],
            boxShadow: styleSchema.olive.boxShadow,
            background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.35) 21.75%, rgba(255, 255, 255, 0) 100%)',
            borderRadius: 40,
            [`@media screen and ${styleSchema.screenMax900}`]: {
                padding: [20, 0]
            },
        }
    } else {
        contentBoxBg = {
            position: 'relative',
            backgroundColor: styleSchema.white.color,
            // padding: [40, 100, 40, 100],
        }
    }
    const useStyles = createUseStyles({
        contentBox: {
            display: 'flex',
            flexDirection: 'row',
            [`@media screen and ${styleSchema.screenMax900}`]: {
                flexDirection: 'column',
            },
        },
        contentBoxBg: contentBoxBg,
        textBox: {
            padding: [0, 20, 0, 20],
            // maxWidth: 300,
            [`@media screen and ${styleSchema.screenMax900}`]: {
                // margin: [0, 40],
            },
            [`@media screen and ${styleSchema.tabScreen}`]: {
                // maxWidth: 300,
            },
            [`@media screen and ${styleSchema.mobScreen}`]: {
                margin: 0,
            },
        },
        description: {
            fontFamily: styleSchema.fontLight,
            fontSize: 18,
            color: styleSchema.greenColorDark.color,
            margin: "auto",
        },
        contentBlock: {
            margin: [0, 0, 10, 0],
        },
        contactText: {
            fontFamily: styleSchema.fontBold,
            fontSize: 16,
            color: styleSchema.greenColorDark.color,
            display: 'flex',
            alignItems: 'center',
        },
        iconsBlock: {
            display: "flex",
            flexDirection: "row",
        },
        contentRow: {
            display: "flex",
            flexDirection: "row",
        },
    })
    return useStyles()
}

export default WhiteBoxTextCenter

