import commonData from "../../assets/data/commonData";
import {tariff} from "../../assets/data/schemaOrg/tariff";
import {Helmet} from "react-helmet";
import React from "react";

export default function TariffHelmet() {
    const title = `${commonData.commonTitle}Тарифы`
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title}/>
            <meta name="description"
                  content="Сдавайте недвижимость в Москве вместе с Kvartel Business. Увеличьте доходы от сдачи в два раза. Выберите удобный тариф управления недвижимостью и наслаждайтесь низкой комиссией."/>
            <meta name="keywords"
                  content="тарифы управления недвижимостью, Kvartel Business тарифы, управление апартаментами, Москва, доверительное управление квартирами, сдача жилья, автоматизация управления недвижимостью, сервис управления арендой"/>
            <meta property="og:title" content={`Тарифы | ${commonData.commonTitleSm}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`${commonData.prodURL}/tariff`}/>
            <meta property="og:image"
                  content={commonData.logo}/>
            <meta property="og:description" content={`Тарифы на услуги ${commonData.commonTitleSm}.`}/>
            <script type={`application/ld+json`}>{JSON.stringify(tariff)}</script>
        </Helmet>
    )
}
