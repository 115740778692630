import React, {Component} from "react"

import SideMenu from './header/SideMenu'
import Toolbar from "./header/Toolbar";
import Backdrop from "./header/Backdrop";

class Header extends Component {
    state = {
        sideMenuOpen: false,
    };

    //toggle click
    menuToggleClickHandler = () => {
        this.setState(prevState => {
            return {sideMenuOpen: !prevState.sideMenuOpen};
        });
    };

    backDropClickHandler = () => {
        this.setState({sideMenuOpen: false});
    };

    render() {
        let backdrop;

        if (this.state.sideMenuOpen) {
            backdrop = <Backdrop click={this.backDropClickHandler}/>;
        }
        return (
            <div className={`sticky-top`}>
                <Toolbar menuToggleClickHandler={this.menuToggleClickHandler} headerStyle={this.state.headerStyle}/>
                <SideMenu show={this.state.sideMenuOpen}/>
                {backdrop}
            </div>
        );
    }
}

export default Header;
