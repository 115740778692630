import {Helmet} from "react-helmet";
import React from "react";
import commonData from "../../assets/data/commonData";
import {home} from "../../assets/data/schemaOrg/home";

export default function HomeHelmet() {
    const title = `${commonData.commonTitle}Управление недвижимостью, тарифы, оборудование`
    return (
        <Helmet>
            <meta name="title"
                  content={title}/>
            <title>{title}</title>
            <meta name="description"
                  content="Доверительное управление недвижимостью с Квартел Бизнес. Узнайте о наших преимуществах, тарифах, оборудовании и рассчитайте доход с вашей недвижимости!"/>
            <meta name="keywords"
                  content="управление недвижимостью, Квартел Бизнес, тарифы, электронный замок, калькулятор дохода, оптимизация управления недвижимостью, доход от недвижимости, сдать квартиру в управление в Москве, сдать квартиру в москве посуточно, официально сдать квартиру в москве"/>

            <meta property="og:title" content={`Управляющая компания ${commonData.commonTitleSm}`}/>
            <meta property="og:description" content="Сдать квартиру в управление для посуточной аренды в Москве и МО"/>
            <meta property="og:image" content={commonData.logo}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`${commonData.prodURL}`}/>

            <script type={`application/ld+json`}>{JSON.stringify(home)}</script>
        </Helmet>
    )
}
