import React, {useState} from "react";
import InputMask from "react-input-mask";
import '../../css/Inputs.scss'
import Button from "./Button";

const gformData = {
    link: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSekZebpTES0zLz4z7S619EkHeDHEgwxWZSsCEFDfk8bgkAMfQ/formResponse",
    entry1: 'entry.2005620554',
    entry2: 'entry.1045781291',
    entry3: "entry.1065046570",
    entry4: "entry.839337160"
}

const  ContactUsForm = (props) => {
    const [submit, setSubmit] = useState(false);
    const [formData, setFormData] = useState({
        "entry.2005620554": "", //fio
        "entry.1045781291": "", //email
        "entry.1065046570": "", //phone
        "entry.839337160": "", //comment
    });

    const handleInputData = (input) => (e) => {
        const {value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [input]: value
        }));
    };
    async function handleSubmit(e) {
        e.preventDefault();
        setSubmit(true);

        let url = `${gformData.link}?${gformData.entry1}=${formData[gformData.entry1]}&${gformData.entry2}=${formData[gformData.entry2]}&${gformData.entry3}=${formData[gformData.entry3]}&${gformData.entry4}=${formData[gformData.entry4]}`;

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    }

    return (
        <div>
            {submit ? alert('Спасибо за обращение, с вами свяжуться в ближайшее время.') : null}
            <form onSubmit={handleSubmit}>
                <div className="row custom-gy-20">
                    <fieldset>
                        {RequiredInput('fio', 'text', 'ФИО', gformData.entry1, handleInputData(gformData.entry1), formData)}
                    </fieldset>
                    <fieldset>
                        {RequiredInput('email', 'text', 'E-mail', gformData.entry2, handleInputData(gformData.entry2), formData)}
                    </fieldset>
                    <fieldset>
                        {PhoneInput(gformData.entry3, handleInputData(gformData.entry3), formData)}
                    </fieldset>
                    <fieldset>
                        {TextAreaInput("your-message", "Комментарий", gformData.entry4, handleInputData(gformData.entry2), formData)}
                    </fieldset>
                    <div className="col-12 d-grid justify-content-center">
                        <Button
                            theme={props.theme}
                            btnText={'Отправить'}
                            id={'submit'}
                            type={'submit'}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
export default ContactUsForm

/**
 * @TextAreaInput
 * @param {string} id
 * @param {string} placeholder
 */
const TextAreaInput = (id, placeholder, entry, handleSubmit, formData) => {
    const [input, setInput] = useState("");
    const handleInputChange = evt => {
        setInput(evt.target.value);
        handleSubmit(evt);
    };

    formData[entry] = input
    return (
        <div className="col-12">
            <span className="" data-name="your-message">
                <div className="form-group">
                    <textarea
                        id={id}
                        placeholder={placeholder}
                        cols="40"
                        rows="10"
                        className='form-control'
                        onChange={handleInputChange}
                        value={input}
                    >
                    </textarea>
                </div>
            </span>
        </div>
    )
}

/**
 * @RequiredInput
 * @param {string} id
 * @param {string} type
 * @param {string} placeholder
 * @returns returns required input with setup it id, type, label, plaseholder
 */
const RequiredInput = (id, type, placeholder, entry, handleSubmit, formData) => {
    const [input, setInput] = useState("");
    const handleInputChange = evt => {
        setInput(evt.target.value);
        handleSubmit(evt);
    };
    const isValid = input !== "";
    formData[entry] = input;

    const [touched, setTouched] = useState(false);

    return (
        <div className="col-12">
            <span className="" data-name={id}>
                <div className="form-group">
                    <InputMask
                        name={entry}
                        type={type}
                        className={touched ? (isValid ? 'form-control' : 'form-control is-invalid') : 'form-control'}
                        id={id}
                        placeholder={placeholder}
                        required
                        value={input}
                        onChange={handleInputChange}
                        onBlur={() => setTouched(true)}
                    />
                    <div
                        className="invalid-feedback">{touched ? (isValid ? '' : 'Пожалуйста, заполните это поле') : null}</div>
                </div>
            </span>
        </div>
    )
}

/**
 * @PhoneInput
 * @returns returns phone input with validation on touch and mask
 */
const PhoneInput = (entry, handleSubmit, formData) => {
    /**
     * On tougch empty field validation
     */
    const [phone, setPhone] = useState("");
    const handlePhoneChange = evt => {
        setPhone(evt.target.value);
        handleSubmit(evt)
    };
    const isValid = phone !== "";
    const [touched, setTouched] = useState(false);
    formData[entry] = phone

    return (
        <div className="col-12">
            <span className="" data-name="phone">
                <div className="form-group">
                    <InputMask
                        name={entry}
                        type="tel"
                        className={touched ? (isValid ? 'form-control' : 'form-control is-invalid') : 'form-control'}
                        id="login"
                        placeholder="Номер телефона"
                        required
                        value={phone}
                        onChange={handlePhoneChange}
                        onBlur={() => setTouched(true)}
                        mask="+7(999)999-99-99"
                    />
                    <div
                        className="invalid-feedback">{touched ? (isValid ? '' : 'Пожалуйста, заполните это поле') : null}</div>
                </div>
            </span>
        </div>
    )
}
