import {AccordionCustom} from "../components/templates/Accordion";
import React from "react";
import {faq} from "../assets/data/faq";
import {createUseStyles} from "react-jss";
import styleSchema from "../css/styleSchema";
import IconLinkBox from "../components/templates/icon/IconLinkBox";
import icons from "../assets/icons";
import commonData from "../assets/data/commonData";
import ContactUsForm from "../components/templates/ContactUsForm";
import img from '../assets/slide4.jpg'
import {Container} from "../components/component/Container";
import typography from "../css/typography";
import FaqHelmet from "../components/helmet/FaqHelmet";

export const Faq = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <FaqHelmet/>
            <Container>
                <div className={`row justify-content-center`}>
                    <div className={`col-12 pt-5 px-4 container`}>
                        <h1 className={`text-center ${typography({color:styleSchema.greenColorDark.color}).h1}`}>Часто задаваемые вопросы</h1>
                    </div>

                    <div className={`col-12 ${classes.conditionsBlock}`}>
                        <AccordionCustom faqData={faq}/>
                    </div>

                    <div className={`col-12 ${classes.contactBlock}`}>
                        <div className={`row m-0  p-0 justify-content-around`}>
                            <div className={`col-auto ${classes.contactForm}`}>
                                <div className={`${classes.image}`}>
                                    <img alt={``} src={img} className={`${classes.img}`}/>
                                </div>
                            </div>
                            <div className={`col-lg-4 ${classes.contactForm}`}>
                                <div className={`row`}>
                                    <div className={`fit`}>
                                        <div className={`row justify-content-center`}>
                                            <div className={`fit ${classes.contactText}`}>Остались вопросы?</div>
                                            <div className={`fit m-2 ${classes.iconsBlock}`}>
                                                <IconLinkBox
                                                    theme={{
                                                        color: styleSchema.white.color,
                                                        size: 32
                                                    }}
                                                    icon={icons.whatsapp}
                                                    link={commonData.whatsapp}
                                                />
                                                <IconLinkBox
                                                    theme={{
                                                        color: styleSchema.white.color,
                                                        size: 32
                                                    }}
                                                    icon={icons.telegram}
                                                    link={commonData.supportTelegram}
                                                />
                                            </div>
                                        </div>
                                        <div className={``}>
                                            <ContactUsForm/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

const useStyles = createUseStyles({

    conditionsBlock: {
        position: 'relative',
        backgroundColor: styleSchema.white.color,
        padding: [40, 100],
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: [20, 0]
        },
    },
    iconsBlock: {
        display: "flex",
        flexDirection: "row",
    },
    contactText: {
        fontSize: 16,
        color: styleSchema.white.color,
        fontFamily: styleSchema.fontBold,
        display: 'flex',
        alignItems: 'center',
        [`@media screen and ${styleSchema.mobScreen}`]: {
            marginTop: 20,
        },
    },
    contactBlock: {
        backgroundColor: styleSchema.olive.color,
        padding: [40, 100],
        [`@media screen and ${styleSchema.mobScreen}`]: {
            padding: 0
        }
    },
    contactForm: {
        display: 'flex',
        alignItems: 'center',
        margin: [20, 0],
    },
    image: {
        position: 'relative',
        //width: "fit-content",
    },
    img: {
        maxHeight: 360,
        borderRadius: [20, 123, 20, 326.5],
        [`@media screen and ${styleSchema.mobScreen}`]: {
            height: 200,
            display: 'flex',
            margin: 'auto',
        },
    },
    calcBlock: {}
})
