export const faq =
    {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Что такое доверительное управление недвижимостью?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Доверительное управление недвижимостью - это услуга, при которой собственник передает свою недвижимость в управление профессиональной компании. Управляющая компания занимается поиском арендаторов, заключением договоров аренды, контролем за состоянием квартиры и решением всех текущих вопросов."
            }
        }, {
            "@type": "Question",
            "name": "Какие преимущества дает доверительное управление недвижимостью?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "<ol><li>Экономия времени и сил. Вам не нужно самостоятельно заниматься поиском арендаторов, показом квартиры, заключением договоров и т.д.</li><li>Гарантированный доход. Управляющая компания гарантирует вам ежемесячный доход от сдачи квартиры в аренду.</li><li>Сохранность имущества. Управляющая компания несет ответственность за сохранность вашей квартиры.</li><li>Юридическая безопасность. Управляющая компания обеспечивает юридическую безопасность сделок аренды.</li></ol>"
            }
        },{
            "@type": "Question",
            "name": "Сколько стоит доверительное управление недвижимостью?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Стоимость доверительного управления недвижимостью обычно составляет от 10% до 20% от стоимости аренды квартиры."
            }
        },{
            "@type": "Question",
            "name": "Как выбрать управляющую компанию?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "При выборе управляющей компании необходимо обратить внимание на следующие факторы:<ol><li>Опыт работы компании.</li><li>Репутация компании.</li><li>Стоимость услуг компании.</li><li>Перечень услуг, предоставляемых компанией.</li></ol>"
            }
        },{
            "@type": "Question",
            "name": "Какие документы нужны для передачи квартиры в доверительное управление?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Для передачи квартиры в доверительное управление необходимо предоставить следующие документы:" +
                    "<ol><li>Свидетельство о праве собственности на квартиру.</li>" +
                    "<li>Паспорт собственника.</li>" +
                    "<li>Договор доверительного управления недвижимостью.</li>" +
                    "<li> Нотариально заверенная доверенность, если квартиру передает уполномоченное лицо.</li></ol>"
            }
        },{
            "@type": "Question",
            "name": "Как расторгнуть договор доверительного управления недвижимостью?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Договор доверительного управления недвижимостью может быть расторгнут по инициативе любой из сторон. Для расторжения договора необходимо уведомить другую сторону в письменной форме за 30 дней."
            }
        },{
            "@type": "Question",
            "name": "Сколько я могу заработать, сдавая апартаменты посуточно?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Заработок от сдачи апартаментов посуточно зависит от ряда факторов, таких как:\n" +
                    "<ol>" +
                    "<li>Локация апартаментов</li>" +
                    "<li>Состояние апартаментов</li>" +
                    "<li>Сезон</li>" +
                    "<li>Цена аренды</li></ol>" +
                    "В среднем, владельцы апартаментов в Москве зарабатывают от 60 000 до 120 000 рублей в месяц. Учтите расходы на обслуживание и управление апартаментами."
            }
        },{
            "@type": "Question",
            "name": "Какие гарантии вы предоставляете?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Мы гарантируем:" +
                    "<ol><li>Безопасность вашего имущества через современные системы безопасности и регулярные проверки.</li>" +
                    "<li>Высокий стандарт чистоты с профессиональной уборкой после каждого гостя.</li>" +
                    "<li>Круглосуточную поддержку для гостей, решение их запросов и проблем.</li>" +
                    "<li>Своевременные и прозрачные выплаты доходов от аренды без задержек.</li></ol>"
            }
        },{
            "@type": "Question",
            "name": "Каким оборудованием должны быть оснащены апартаменты?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Для комфортного проживания гостей апартаменты должны быть оснащены следующим оборудованием:" +
                    "<ol><li>Мебель: кровати, шкафы, столы, стулья, диван.</li>" +
                    "<li>Бытовая техника: холодильник, плита, чайник, микроволновая печь, стиральная машина.</li>" +
                    "<li>Посуда и кухонные принадлежности: тарелки, чашки, ложки, вилки, кастрюли, сковородки.</li>" +
                    "<li>Текстиль: постельное белье и полотенца.</li>" +
                    "<li>Средства гигиены: мыло, шампунь, гель для душа, туалетная бумага.</li>" +
                    "<li>Интернет с Wi-Fi.</li>" +
                    "<li>Дополнительное оборудование и удобства по желанию, такие как телевизор, утюг, гладильная доска и фен.</li></ol>"
            }
        },{
            "@type": "Question",
            "name": "Как я могу сдать апартаменты посуточно самостоятельно?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Вы можете сдать апартаменты посуточно самостоятельно, разместив объявления на специализированных сайтах. Вам также необходимо будет самостоятельно заниматься уборкой апартаментов, заселением и выселением гостей, а также отвечать на вопросы гостей."
            }
        },{
            "@type": "Question",
            "name": "Какие преимущества дает сотрудничество с вашей компанией?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Сотрудничество с нашей компанией дает вам следующие преимущества:" +
                    "<ol>" +
                    "<li>Вы экономите время и силы.</li>" +
                    "<li>Вы получаете максимальную прибыль от сдачи апартаментов посуточно.</li>" +
                    "<li>Вы можете быть уверены, что ваши апартаменты в безопасности.</li></ol>"
            }
        },{
            "@type": "Question",
            "name": "Какой тариф мне подходит?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Чтобы определить, какой тариф Kvartel лучше всего подходит для вас, важно учитывать различные факторы, такие как объем вашего бизнеса, специфические потребности в управлении недвижимостью, и ваш бюджет. На странице тарифов Kvartel представлены разные варианты, каждый из которых предлагает уникальный набор услуг и возможностей. Рекомендуется тщательно изучить каждый тариф, чтобы понять, какие функции вам наиболее важны и какой тариф лучше всего соответствует вашим требованиям. Для более подробной информации, пожалуйста, посетите <a href='/tariff'>страницу тарифов Kvartel.</a>"
            }
        },{
            "@type": "Question",
            "name": "Могу ли я перейти на другой тариф?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Да, вы можете перейти на другой тариф в любое время. Для этого вам необходимо связаться с вашим менеджером и сообщить о своем желании, а также обсудить условия перехода."
            }
        }
        ]
    }
