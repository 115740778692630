import {createUseStyles} from "react-jss";
import React from "react"
import styleSchema from "../../../css/styleSchema";

const IconLinkBox = props => {
    const classes = Style(props.theme);

    return (
        <div className={classes.iconBox}>
            <a href={props.link} target="_blank" rel="noreferrer nofollow"
               className={classes.iconLink}>
                <i className={``}>{props.icon}</i>
            </a>
        </div>
    )
}

const Style = (theme) => {
    let useStyles

    if (theme === 'violet') {
        useStyles = createUseStyles({
            iconBox: {
                backgroundColor: styleSchema.iconBoxColor,
                borderRadius: 30,
                width: 106,
                height: 106,
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                [`@media screen and ${styleSchema.mobScreen}`]: {
                    width: 80,
                    height: 80,
                },
            },
            iconLink: {
                '& svg': {
                    width: 60,
                    height: 60,
                    color: styleSchema.violetColor.color,
                    [`@media screen and ${styleSchema.mobScreen}`]: {
                        width: 40,
                        height: 40,
                    },
                },
                '&:hover': {
                    color: styleSchema.violetColor.color
                },
            },
        })

    } else {
        useStyles = createUseStyles({
            iconBox: {},
            iconLink: {
                color:theme.color,
                marginRight: 30,
                [`@media screen and ${styleSchema.mobScreen}`]: {
                    paddingLeft: 15,
                    marginRight: 0,
                },
                '& svg': {
                    width: theme.size,
                    height: theme.size,
                    [`@media screen and ${styleSchema.mobScreen}`]: {
                        width: theme.size,
                        height: theme.size,
                    },
                },
                '&:hover': {
                    color: theme.color,
                },
            },
        })
    }
    return useStyles()
}

export default IconLinkBox
