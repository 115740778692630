import lockKvartel from "../../lock/lockKvartel.webp";
import cylinder from "../../lock/CYLINDER2.webp";
import miniceif from "../../lock/miniceif.webp";
import BluetoothGateway from "../../lock/wifi.webp";
import commonData from "../commonData";

const shippingDetails = [
    {
        "@type": "OfferShippingDetails",
        "shippingRate": {
            "@type": "MonetaryAmount",
            "value": "0",
            "currency": "RUB"
        },
        "shippingDestination": {
            "@type": "DefinedRegion",
            "addressCountry": "RU",
            "addressRegion": commonData.addressLocality
        },
        "deliveryTime": {
            "@type": "ShippingDeliveryTime",
            "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 0,
                "maxValue": 1,
                "unitCode": "DAY"
            },
        }
    }
]
export const locksList = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
        {
            "@type": "Product",
            "name": "ЗАМОК KVARTEL",
            "image": lockKvartel,
            "description": "Замок KVARTEL – идеален для мини-отелей, апартаментов, офисов как электронный замок с дистанционным управлением и открытием.",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "11500",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition",
                "priceValidUntil": "2024-12-31",
                "hasMerchantReturnPolicy": {
                    "@type": "MerchantReturnPolicy",
                    "returnPolicyCategory": "https://schema.org/MerchantReturnNotPermitted",
                    "applicableCountry": "RU",
                },
                "shippingDetails": shippingDetails,
            }
        },
          {
              "@type": "Product",
              "name": "SMART-CYLINDER",
              "image": cylinder,
              "description": "Электронный цилиндр управляется RFID, кодом, смартфоном. Устанавливается в любой замок за 5 минут одной отверткой.",
              "offers": {
                  "@type": "Offer",
                  "priceCurrency": "RUB",
                  "price": "9500",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition",
                  "priceValidUntil": "2024-12-31",
                  "hasMerchantReturnPolicy": {
                      "@type": "MerchantReturnPolicy",
                      "returnPolicyCategory": "https://schema.org/NoReturns",
                      "applicableCountry": "RU",
                  },
                  "shippingDetails":shippingDetails
              }
          },
          {
              "@type": "Product",
              "name": "KVARTEL MINI KEY SAFE",
              "image": miniceif,
              "description": "Экономное решение для бесконтактной передачи ключей. 4-значный пароль, надежное крепление и замор – гарантия сохранности ваших ключей.",
              "offers": {
                  "@type": "Offer",
                  "priceCurrency": "RUB",
                  "price": "2590",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition",
                  "priceValidUntil": "2024-12-31",
                  "hasMerchantReturnPolicy": {
                      "@type": "MerchantReturnPolicy",
                      "returnPolicyCategory": "https://schema.org/NoReturns",
                      "applicableCountry": "RU",
                  },
                  "shippingDetails": shippingDetails
              }
          },
          {
              "@type": "Product",
              "name": "WIFI GATE G2",
              "image": BluetoothGateway,
              "description": "WIFI-шлюз от Kvarter для дистанционного управления замками. Управляйте и отслеживайте события замка онлайн откуда угодно.",
              "offers": {
                  "@type": "Offer",
                  "priceCurrency": "RUB",
                  "price": "3800",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition",
                  "priceValidUntil": "2024-12-31",
                  "hasMerchantReturnPolicy": {
                      "@type": "MerchantReturnPolicy",
                      "returnPolicyCategory": "https://schema.org/NoReturns",
                      "applicableCountry": "RU",
                  },
                  "shippingDetails": shippingDetails
              }
          }
    ]
}
