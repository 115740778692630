import React, {Component} from "react";


export class Radio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            apartmentsTypes: {
                1: '1 к. кв',
                2: '2 к. кв',
                3: '3 к. кв'
            },
            apartmentPick: '',
            districts: {
                a: 'ЮЗАО',
                b: 'СВАО',
                c: 'СЗАО',
                d: 'ЮВАО',
                e: 'ЦАО',
            },
            districtPick: '',
            results: {
                1: {
                    a: '76 500',
                    b: '73 500',
                    c: '74 100',
                    d: '69 000',
                    e: '144 000',
                },
                2: {
                    a: '82 500',
                    b: '79 500',
                    c: '80 100',
                    d: '75 000',
                    e: '150 000',
                },
                3: {
                    a: '85 200',
                    b: '82 200',
                    c: '82 800',
                    d: '77 700',
                    e: '152 700',
                }
            },
            currentResult: ''
        }
    }

    handleRadio1(e) {
        this.setState({apartmentPick: e.target.value})
    }

    handleRadio2(e) {
        this.setState({districtPick: e.target.value})
    }

    render() {
        const {
            apartmentsTypes, apartmentPick,
            districts, districtPick,
            results
        } = this.state

        const apartmentsOptions = Object.keys(apartmentsTypes).map(id => {
            const isCurrent = apartmentPick === id
            return (
                <div
                    key={id}
                    className="radioPad"
                >
                    <div>
                        <label
                            className={
                                isCurrent ?
                                    'radioPad__wrapper radioPad__wrapper--selected' :
                                    'radioPad__wrapper'
                            }
                        >
                            <input
                                className="radioPad__radio"
                                type="radio"
                                name="apartmentsTypes"
                                id={id}
                                value={id}
                                onChange={this.handleRadio1.bind(this)}
                            />
                            {apartmentsTypes[id]}
                        </label>
                    </div>
                </div>
            )
        })

        const districtsOptions = Object.keys(districts).map(id => {
            const isCurrent = districtPick === id
            return (
                <div
                    key={id}
                    className="radioPad"
                >
                    <div>
                        <label
                            className={
                                isCurrent ?
                                    'radioPad__wrapper radioPad__wrapper--selected' :
                                    'radioPad__wrapper'
                            }
                        >
                            <input
                                className="radioPad__radio"
                                type="radio"
                                name="districts"
                                id={id}
                                value={id}
                                onChange={this.handleRadio2.bind(this)}
                            />
                            {districts[id]}
                        </label>
                    </div>
                </div>
            )
        })

        Object.keys(results).map(apt => {
            if (apartmentPick) {
                const currentApt = results[apartmentPick]
                if (districtPick) {
                    Object.keys(currentApt).map(c => {
                        this.state.currentResult = currentApt[districtPick]
                    })
                } else this.state.currentResult = 'Выберите район'
            } else this.state.currentResult = 'Выберите квартиру'
        })

        return (
            <div className="calc-block">
                <div className="row align-items-center">
                    <div className={`col-4`}>
                        <div className={`calc-title`}>
                            Выберите квартиру:
                        </div>
                    </div>
                    <div className={`col`}>
                        <div className={`calc-title`}>{apartmentsOptions}</div>
                    </div>
                </div>
                <div className={`row align-items-center`}>
                    <div className={`col-4`}>
                        <div className={`calc-title`}>
                            Выберите район:
                        </div>
                    </div>
                    <div className={`col`}>
                        <div className={`calc-title`}>{districtsOptions}</div>
                    </div>
                </div>
                <div className={`row align-items-center mt-3`}>
                    <div className={`col-4`}>
                        <div className={`calc-title`}>
                            Ваш зароботок*:
                        </div>
                    </div>
                    <div className={`col`}>
                        <div className={`calc-result`}>
                            {`${this.state.currentResult}`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

