import {createUseStyles} from "react-jss";
import icons from "../../assets/icons";
import React from "react";
import styleSchema from "../../css/styleSchema";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const DataTable = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {props.data.map((service, index) => {
                return (
                    <Table key={index}>
                        <Thead className={classes.tableHeader}>
                            <Tr>
                                <Th className={classes.thService}>{service.thService}</Th>
                                {service.thPlan.map((plan, planIndex) => {
                                    return (
                                        <Th key={planIndex} className={classes.thPlan}>{plan}</Th>
                                    )
                                })}
                            </Tr>
                        </Thead>
                        <Tbody className={classes.tbody}>
                            {service.tdData.map((serviceItem, itemIndex) => {
                                return (
                                    <Tr className={classes.trData}>
                                        <Td className={classes.thService}>{` ${serviceItem.service}`}</Td>

                                        {serviceItem.data.map((item, itemKey) => {
                                            if (item === true) {
                                                return (
                                                    <Td key={itemKey} className={classes.thPlan}>
                                                        <div className={classes.icon}>
                                                            <img alt={`check`} className={classes.listIcon}
                                                                 src={icons.checkCircle2}>
                                                            </img>
                                                        </div>
                                                    </Td>
                                                )
                                            } else {
                                                if (item === false) {
                                                    return (<Td className={classes.thPlan} key={itemKey}></Td>)
                                                } else {
                                                    return (
                                                        <Td className={classes.thPlan} key={itemKey}>{`${item}`}</Td>
                                                    )
                                                }
                                            }
                                        })}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                )
            })}
        </React.Fragment>
    )
}

const useStyles = createUseStyles({
    tbody: {
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    tableHeader: {
        backgroundColor: styleSchema.olive.color,
        color: "white",
        fontFamily: styleSchema.fontBold,
        fontSize: 16,
    },
    thService: {
        padding: [15, 40],
        // Основные стили
        '&': {
            minWidth: '100%',
        },
        // Медиа-запрос минимум 960 экран
        [`@media screen and (min-width: 1160px)`]: {
            minWidth: '25rem',
        },
        [`@media screen and ${styleSchema.mobScreen}`]: {
           padding:0,
        },
    },
    thPlan: {
        padding: 15,
        textAlign: "center",
        // Основные стили
        '&': {
            minWidth: '100%',
        },
        // Медиа-запрос минимум 960 экран
        [`@media screen and (min-width: 1160px)`]: {
            minWidth: '15rem',
        },
    },
    icon: {},
    listIcon: {
        height: 20,
        width: 20,
        filter: `drop-shadow(0px 1px 1px rgba(${styleSchema.olive.colorRBG}, 0.4))`,
    },
    trData: {
        borderBottom: `solid 1px ${styleSchema.olive.color}`,
    },
})


export default DataTable;

