import React from "react";
import icons from "../../../assets/icons";
import styleSchema from "../../../css/styleSchema";
import {createUseStyles} from "react-jss";
import IconBox from "../icon/IconBox";
import WhiteBoxTextCenter from "../WhiteBoxTextCenter";
import typography from "../../../css/typography";

const HowWeWork = () => {
    const classes = Style();

    return (
        <div className={`container`}>
            <WhiteBoxTextCenter
                title={<h2 style={typography({color: styleSchema.greenColorDark.color}).h2}>{blockTitle}</h2>}
                description={blockDescription}
                link={''}
                linkName={''}
                content={
                    <div className={`row justify-content-center`}>
                        <div className={`col-12`}>
                            <div className={`row justify-content-center`}>
                                {itemData.map((item, index) => {
                                    return (
                                        <div className={`col-lg-4 col-md-12 col-sm-12 mb-5`} key={index}>
                                            <div className={classes.iconBlock}>
                                                <IconBox icon={item.itemIcon}/>
                                            </div>
                                            <div className={`${classes.textBlock} justify-content-center mt-4`}>
                                                <div className={`${classes.itemText}`}>
                                                    <div className={`${classes.itemTitle}`}>
                                                        {item.itemTitle}
                                                    </div>
                                                    <div className={`${classes.itemDescription}`}>
                                                        {item.itemDescription}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    )
}
export default HowWeWork;

const blockTitle = 'Как мы работаем';
const blockDescription = 'С Kvartel Business недвижимость превращается в полноценный источник стабильного пассивного дохода.';
const itemData = [
    {
        itemTitle: 'Договор',
        itemDescription: 'Вы выбираете тариф управления. Обсуждаем и подписываем договор, делаем страховку недвижимости на сумму до 1 400 000 р.',
        itemIcon: icons.signatureIcon
    },
    {
        itemTitle: 'Подключение',
        itemDescription: 'После проверок и установки оборудования, ваше жильё добавится в систему Kvartel и будет готово к сдаче.',
        itemIcon: icons.houseIcon
    },
    {
        itemTitle: 'Отчёт',
        itemDescription: 'Приложение Kvartel Business предоставляет полную отчётность. Все бронирования, клининги и ремонты фиксируются в системе.',
        itemIcon: icons.reportIcon
    },
]

const Style = () => {
    const useStyles = createUseStyles({
        conditionsBlock: {
            position: 'relative',
            backgroundColor: styleSchema.white.color, // padding: [50, 0],
        },
        textBox: {
            textAlign: "center",
            padding: [0, 20, 0, 20]
        },

        description: {
            fontFamily: styleSchema.fontLight,
            fontSize: 18,
            color: styleSchema.greenColorDark.color,
            margin: "auto",
        },
        contentBlock: {
            margin: [50, 0, 10, 0],
        },
        decorativeLine: {
            maxHeight: 83,
            margin: [0, 20, 0, 20],
        },
        textBlock: {
            display: "flex",
        },
        itemText: {
            maxWidth: 350,
            textAlign: "center",
            padding: [0, 20, 0, 20]
        },
        itemTitle: {
            fontSize: 16,
            fontFamily: styleSchema.fontBold,
            textTransform: "uppercase",
            color: styleSchema.greenColorDark.color,
        },
        itemDescription: {
            fontSize: 16,
            fontFamily: styleSchema.fontLight,
            color: styleSchema.greenColorDark.color,
        },
        iconBlock: {
            margin: "auto",
            width: "fit-content",
        },
    })
    return useStyles()
}
