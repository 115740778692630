import styleSchema from "../../css/styleSchema";
import {createUseStyles} from "react-jss";
import React from "react";
import {Link} from "react-router-dom";

const LinkButton = (props) => {
    const classes = Style();
    return (
        <Link
            to={props.link}
            className={`${classes.link}`}
            /*smooth={true}*/
            offset={-70}
            duration={500}
        >
            <div className={`${classes.linkButton}`} key={props.index}>
                {props.linkName}
            </div>
        </Link>
    )
}

const Style = () => {
    const useStyles = createUseStyles({
        linkButton: {
            borderRadius: 5,
            fontFamily: styleSchema.fontMedium,
            backgroundColor: styleSchema.olive.color,
            color: styleSchema.white.color,
            padding: 10,
            textAlign: "center",
            marginTop: 10,
            '&:hover': {
                border: styleSchema.olive.border,
                backgroundColor: styleSchema.white.color,
                color: styleSchema.olive.color,
                boxShadow:styleSchema.olive.boxShadow,
            },
        },
        link: {
            color: styleSchema.white.color,
            textDecoration: "none",
            '&:hover': {
                color: styleSchema.olive.color
            },
        },
    })
    return useStyles()
}
export default LinkButton
