import './App.scss';
import {Layout} from "./components/Layout";
import React, {Component} from 'react';

import {Route, Routes} from "react-router";

import AppRoutes from "./routes/AppRoutes";

import AOS from 'aos';
import 'aos/dist/aos.css';
import NotFoundPage from "./pages/NotFoundPage";


export default class App extends Component {
    componentDidMount() {
        AOS.init();
         // AOS.init();
    }

    static displayName = App.name;

    render() {
        return (
            <div>
                <Layout>
                    <Routes>
                         {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                        <Route path="*" element={<NotFoundPage/>} status={404} />
                    </Routes>
                </Layout>
            </div>
        );
    }
}
