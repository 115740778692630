import {Accordion} from "react-bootstrap";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";

export const AccordionCustom = (props) => {
    const classes = useStyles()
    return (
        <Accordion alwaysOpen>
            <div className={`row`}>
                {props.faqData.mainEntity.map((item, index) => {
                    return (
                        <Accordion.Item
                            eventKey={`accordion_${index}`}
                            itemScope
                            itemProp="mainEntity"
                            itemType="https://schema.org/Question"
                            key={index}
                            className={`col-lg-4 col-sm-12`}
                        >
                            <Accordion.Header itemProp="name" className={classes.header}>
                                {item.name}
                            </Accordion.Header>
                            <Accordion.Body itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text" dangerouslySetInnerHTML={{ __html: item.acceptedAnswer.text }}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </div>
        </Accordion>
    )
}
const useStyles = createUseStyles({
    header: {
        fontFamily: styleSchema.fontBold
    }
})
