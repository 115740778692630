const reviews = [
    {
        clientName:"Георгий О.",
        period:"Срок сотрудничества - более года",
        text: "Хотел бы поделиться своим опытом сдачи 3 апартаментов  через управляющую компанию Квартель Бизнес. Находясь за границей, я доверил управление своими объектами этой компании и хочу выразить благодарность за высокий уровень обслуживания и поддержку, которую они предоставляют."
    },
    {
        clientName:"Валентин К.",
        period:"Срок сотрудничества - более 1,5 года",
        text: "Более полутора лет сотрудничаю с \"Квартель Бизнес\". Могу подтвердить их высокий профессионализм: через кабинет всегда доступна статистика по объектам, что невероятно удобно, особенно находясь за границей."
    },
    {
        clientName:"Роман В.",
        period:"Срок сотрудничества - более полугода",
        text: "Я за 2 года сменил несколько компаний. Но , после начала сотрудничества с \"Квартель Бизнес\", я убедился, что это – лучший выбор из всех, с кем мне доводилось работать. \n" +
            "Доволен по качеству и по доходу. Респект ребятам 👍"
    },
    {
        clientName:"Олег Ц.",
        period:"Срок сотрудничества - более полугода",
        text: "Заказали установку 10 замков. Через время решили разместить свои объекты на Kvartel для увеличения занятости квартир. По обслуживанию замков нареканий не имеем."
    },
    {
        clientName:"Татьяна П.",
        period:"Срок сотрудничества - более 1,5 года",
        text: "Благодарю команду Kvartel за ответственный подход и прозрачность в работе. Отчетность всегда доступна в приложении, и я в курсе всех событий связанных с моей недвижимостью."
    }
]
export default reviews
