import React from "react";
import {createUseStyles} from "react-jss";
import styleSchema from "../../css/styleSchema";
import icons from "../../assets/icons";
import Button from "./Button";

export class Popup extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showPopup: false
        };
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    render() {
        const {content, popupButton, popupTitle} = this.props;

        return (
            <div className={` d-flex justify-content-center`}>
                <div className='app h-100'>
                    <Button onClick={this.togglePopup.bind(this)} btnText={popupButton}/>
                    {this.state.showPopup ?
                        <PopupInner
                            text={popupTitle}
                            closePopup={this.togglePopup.bind(this)}
                            content={content}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }
}

const PopupInner = (props) => {
    const classes = Style();
    return (
        <div className={`${classes.popup}`}>
            <div className={`${classes.popupInner} `}>
                <div className={`row m-0 d-flex justify-content-end `}>
                    <button onClick={props.closePopup} className={`${classes.closeBtn} m-0 p-0`}>
                        <i className={`${classes.icon}`}>{icons.close}</i>
                    </button>
                </div>
                <div className={`row m-0`}>
                    <h2 className={`${classes.popupTitle} mt-3 mb-3`}>{props.text}</h2>
                </div>
                <div className={`text-start ${classes.contactForm}`}>
                    {props.content}
                </div>
            </div>
            {props.backdrop}
        </div>
    );
}
const Style = () => {
    const useStyles = createUseStyles({
            popup: {
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1021,
                display: "flex",
            },
            popupInner: {
                margin: 'auto',
                background: styleSchema.greenColorDark.color,
                height: 'fit-content',
                width: 'fit-content',
                borderRadius: 40,
                padding: 40,
            },
            closeBtn: {
                backgroundColor: 'transparent',
                marginRight: 25,
                border: 'none',
                width: 'fit-content',
                height: '100%',
            },
            icon: {
                color: styleSchema.white.color,
                '& svg': {
                    width: 20,
                    height: 20,
                },
            },
            popupTitle: {
                color: styleSchema.white.color,
                fontSize: 16,
                fontFamily: styleSchema.fontMedium,
                textAlign: "center",
                textTransform: "uppercase",
            },
            contactForm: {
                maxWidth: 500,
            },
        }
    )
    return useStyles()
}
