import styleSchema from "../../../css/styleSchema";
import {createUseStyles} from "react-jss";
import MenuToggleButton from "./menuToggleButton";
import NavMenuRoutes from "../../../routes/NavMenuRoutes";
import React, {useEffect, useState} from "react";
import logoCommon from "../../../assets/logo/logoDark.svg";
import commonData from "../../../assets/data/commonData";
import icons from "../../../assets/icons";
import IconLinkBox from "../icon/IconLinkBox";
import {Popup} from "../Popup";
import ContactUsForm from "../ContactUsForm";

const toolbar = props => {
    const classes = Style();

    /** change header style on scroll*/
        // eslint-disable-next-line react-hooks/rules-of-hooks
    const [small, setSmall] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () => setSmall(window.scrollY > 200));
        }
    }, []);

    return (
        <header className={small ? `${classes.header} row p-0 m-0 ` : `${classes.header} row p-0 m-0`}>
            <div className={`${classes.headerNav} col-lg-9 col-md-12 col-sm-12`}>
                <nav className={`${classes.toolbarNavigator}`}>
                    <div className={`${classes.logoBlock}`}>
                        <a href="/" className={`align-items-baseline d-flex text-decoration-none`}>
                            <img className={classes.logo} src={logoCommon} alt="logo"/>
                            <div className={`d-none d-sm-block ${classes.logoText} `}>Business</div>
                        </a>
                    </div>
                    <div className={classes.navigationItems}>
                        <ul className={classes.navList}>
                            {NavMenuRoutes.map((navItem, index) => {
                                return (<li key={index}>
                                    <a
                                        className={small ? `${classes.stickyNavLink} ${classes.navLink}` : classes.navLink}
                                        href={navItem.path}
                                    >
                                        {navItem.title}
                                    </a>
                                </li>)
                            })}
                        </ul>
                    </div>
                    <div className={classes.toggleBtn}>
                        <MenuToggleButton click={props.menuToggleClickHandler}/>
                    </div>
                </nav>
            </div>
            <div className={`${classes.headerContact} col-lg-3 col-md-12 col-sm-12`}>
                <div className={`row justify-content-center`}>
                    <div className={`${classes.contactText} fit `}>Написать нам</div>
                    <div className={`fit ${classes.iconsBlock}`}>
                        <IconLinkBox
                            theme={{
                                color: styleSchema.white.color,
                                size: 24
                            }}
                            icon={icons.whatsapp} link={commonData.whatsapp}/>
                        <IconLinkBox
                            theme={{
                                color: styleSchema.white.color,
                                size: 24
                            }}
                            icon={icons.telegram} link={commonData.supportTelegram}/>
                    </div>
                </div>
                <div className={`row ${classes.contactUsBtn}`}>
                    <Popup
                        popupButton={'Оставить заявку'}
                        popupTitle={'Оставить заявку'}
                        content={<ContactUsForm/>}
                    />
                </div>
            </div>
        </header>
    )
}

const Style = () => {
    const useStyles = createUseStyles({
        header: {
            backgroundColor: styleSchema.greenColorDark.color,
        },
        headerNav: {
            backgroundColor: styleSchema.white.color,
            borderRadius: [0, 0, 40, 0],
            height: "fit-content",
            padding: [15, 40, 15, 40],
            marginRight: "auto",
            [`@media screen and ${styleSchema.tabScreen}`]: {
                padding: 0,
            },
            [`@media screen and ${styleSchema.mobScreen}`]: {
                padding: 0,
                borderRadius: 0,
            },
        },
        stickyHeader: {
            backgroundColor: styleSchema.greenColorDark,
        },
        toolbarNavigator: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            padding: [0, '1rem'],
            [`@media screen and ${styleSchema.mobScreen}`]: {},
            justifyContent: "space-between",
        },
        logoBlock: {
            //marginBottom: '1em',
            alignItems: "start",
            display: "flex",
        },
        logo: {
            maxHeight: 25,
            [`@media screen and ${styleSchema.mobScreen}`]: {
                marginLeft: 0,
                height: 20,
            },
            [`@media screen and (min-width: 759px)`]: {
                marginLeft: 0,
            }
        },
        logoText: {
            fontFamily: styleSchema.fontBold,
            fontSize: 35,
            color: styleSchema.greenColorDark.color,
        },
        navigationItems: {
            [`@media screen and ${styleSchema.mobScreen}`]: {
                display: 'none',
            },
        },
        navList: {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
        },
        navLink: {
            fontFamily: styleSchema.fontBold,
            display: 'block',
            fontSize: '1rem',
            padding: '0.5rem',
            textDecoration: 'none',
            color: styleSchema.greenColorDark.color,
            margin: [0, 10],
            textTransform: 'capitalize',

            '&:hover': {
                transition: 'background-color 1s ease-out 100ms',
                backgroundColor: styleSchema.olive.color,
                color: styleSchema.white.color,
                borderRadius: 24,
            },
        },
        stickyNavLink: {
            color: styleSchema.greenColorDark.color
        },
        spacer: {
            flex: "auto",
        },
        toggleBtn: {
            [`@media screen and (min-width: 759px)`]: {
                display: 'none',
            }
        },
        headerContact: {
            maxWidth: "fit-content",
            marginRight: "auto",
            paddingBottom: '1rem',
            [`@media screen and ${styleSchema.mobScreen} `]: {
                marginTop: '1rem',
                maxWidth: "fit-content",
                marginRight: "auto",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '1rem',
            },
            [`@media screen and ${styleSchema.tabScreen}`]: {
                marginTop: '1rem',
                maxWidth: "fit-content",
                marginRight: "auto",
                display: 'flex',
                flexDirection: 'row', // Здесь
                alignItems: 'center', // и здесь
                justifyContent: 'center',
                paddingBottom: '1rem',
            },
        },
        iconsBlock: {
            display: "flex",
            flexDirection: "row",
            alignItems: 'center',
            margin: '0.5rem',
            [`@media screen and ${styleSchema.mobScreen}`]: {
                margin: 0,
            }
        },
        contactText: {
            fontSize: '1rem',
            color: styleSchema.white.color,
            fontFamily: styleSchema.fontBold,
            display: 'flex',
            alignItems: 'center',
            [`@media screen and ${styleSchema.tabScreen}`]: {},
            [`@media screen and ${styleSchema.mobScreen}`]: {}
        },
        contactUsBtn: {
            [`@media screen and ${styleSchema.mobScreen}`]: {
                display: 'none',
            }
        },
    })
    return useStyles()
}

export default toolbar;
